import React, { useEffect, useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FullscreenExit from '@mui/icons-material/FullscreenExit'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import {
  ControlBoxStyled,
  ControlContainerStyled,
  ControlWrapperStyled,
  IconButtonStyled,
  InnerControlStyled,
  ProgressBoxStyled,
  StyledReloadButton,
  VideoProgressStyled,
  VideoWrapper,
  VolumeStyled,
} from './Styled'
import {
  formatTime,
  handlePlayPause,
  handleFullscreen,
  handleSeekMouseDown,
  handleSeekEnd,
  handleSeeking,
  handleMute,
  handleVolumeChange,
  handleHoverOnVideo,
  handleKeyDown,
  handleReload,
} from './events'

const CustomControl = ({ url, wrapperRef, videoPlayerRef }) => {
  const controlRef = useRef(null)
  const dispatch = useDispatch()
  const { setting, selectedLesson, isFullScreen } = useSelector(
    (state) => ({
      setting: state.eLearningLearning.setting,
      lessons: state.eLearningLearning.lessons,
      isFullScreen: state.eLearningLearning.isFullScreen,
      selectedLesson: state.eLearningLearning.selectedLesson,
    }),
    shallowEqual
  )
  const videoState = _.get(selectedLesson, 'videoState', 0)
  const { playing, muted, volume, played, isFinished } = videoState
  const current = _.get(videoState, 'playedSeconds', 0)
  const duration = _.get(selectedLesson, 'duration', 0) || 0
  const formatCurrentTime = useMemo(() => formatTime(current), [url, current])
  const formatDuration = useMemo(() => formatTime(duration), [url, duration])
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    handleHoverOnVideo(controlRef)
  }, [])

  useEffect(() => {
    dispatch(handleKeyDown(videoPlayerRef))
  }, [videoState, setting])

  return (
    <ControlWrapperStyled ref={controlRef}>
      <VideoWrapper
        onDoubleClick={() => handleFullscreen(wrapperRef)}
        onClick={() => dispatch(handlePlayPause())}
      />

      <ControlContainerStyled>
        {/* play / pause button */}
        <IconButtonStyled
          sx={{ width: 34, height: 34 }}
          onClick={() => dispatch(handlePlayPause())}
        >
          {isFinished ? (
            <img src="/icon/e-learning/player/replay.svg" />
          ) : playing ? (
            <img src="/icon/e-learning/player/pause.svg" />
          ) : (
            <img src="/icon/e-learning/player/play.svg" />
          )}
        </IconButtonStyled>

        {/* progress bar */}
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <InnerControlStyled>
            <Typography>
              {isFinished ? formatDuration : formatCurrentTime}
            </Typography>
          </InnerControlStyled>
          <ProgressBoxStyled>
            <VideoProgressStyled
              min={0}
              max={100}
              value={played * 100}
              onMouseDown={() => dispatch(handleSeekMouseDown())}
              onChange={(e, value) => dispatch(handleSeeking(value))}
              onChangeCommitted={(e, value) =>
                dispatch(handleSeekEnd(value, videoPlayerRef))
              }
            />
          </ProgressBoxStyled>
          <InnerControlStyled>
            <Typography>{formatDuration}</Typography>
          </InnerControlStyled>
        </Box>

        <ControlBoxStyled>
          {/* mute button */}
          <IconButtonStyled onClick={() => dispatch(handleMute(videoState))}>
            {muted ? (
              <img src="/icon/e-learning/player/mute.svg" />
            ) : (
              <img src="/icon/e-learning/player/volume.svg" />
            )}
          </IconButtonStyled>
          {/* volume control */}
          {isNotSm && (
            <VolumeStyled
              size={isNotSm ? 'medium' : 'small'}
              value={volume * 100}
              onChange={(e, value) => dispatch(handleVolumeChange(value))}
              onChangeCommitted={(e, value) =>
                dispatch(handleVolumeChange(value))
              }
            />
          )}
        </ControlBoxStyled>

        <StyledReloadButton onClick={handleReload}>โหลดใหม่</StyledReloadButton>

        {/* full screen button */}
        <IconButtonStyled
          onClick={() => dispatch(handleFullscreen(wrapperRef))}
        >
          {isFullScreen ? (
            <FullscreenExit />
          ) : (
            <img src="/icon/e-learning/player/full_screen.svg" />
          )}
        </IconButtonStyled>
      </ControlContainerStyled>
    </ControlWrapperStyled>
  )
}

export default CustomControl
