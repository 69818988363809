import _ from 'lodash'
import screenfull from 'screenfull'
import {
  setFullScreen,
  setVideoState,
  stopVideoLoading,
} from 'src/redux/slices/eLearning/learning'
import { getLearningState, handleUpdateLogPerLesson } from '../../../events'

export const handleWindowBlur = () => (dispatch) => {
  const handleClickOutside = () => {
    const { selectedLesson } = getLearningState()
    const videoState = _.get(selectedLesson, 'videoState', {})
    if (!videoState.playing) return

    dispatch(setVideoState({ playing: false }))
  }

  window.addEventListener('blur', handleClickOutside)
  return () => {
    window.removeEventListener('blur', handleClickOutside)
  }
}

export const handleVideoReady = (videoPlayerRef) => (dispatch) => {
  const { selectedLesson } = getLearningState()
  const duration = _.get(selectedLesson, 'duration', 0)
  const videoState = _.get(selectedLesson, 'videoState', {})
  const playedSeconds = _.get(videoState, 'playedSeconds', 0)
  const played = playedSeconds / duration
  const timeToStart = _.isNil(playedSeconds) ? 0 : playedSeconds
  videoPlayerRef.current.seekTo(timeToStart, 'seconds')
  dispatch(stopVideoLoading())
  dispatch(setVideoState({ playing: false, played }))
}

export const progressHandler = (state) => (dispatch) => {
  if (state.loaded) dispatch(setVideoState({ ...state }))

  const { selectedLesson } = getLearningState()
  const videoState = _.get(selectedLesson, 'videoState', {})
  const playedSeconds = _.get(videoState, 'playedSeconds', 0)

  const roundSec = Number(playedSeconds.toFixed())
  // save seconds of video every ten secs
  if (roundSec !== 0 && roundSec % 10 === 0) {
    dispatch(handleUpdateLogPerLesson())
  }
}

export const handleEnded = () => async (dispatch) => {
  dispatch(setVideoState({ playing: false, isFinished: true }))
  await dispatch(handleUpdateLogPerLesson(true))
}

export const handleOrientation = () => (dispatch) => {
  const handleResetFullScreen = () => {
    dispatch(setFullScreen(false))
    if (screenfull?.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull
          .exit()
          .then(() => {
            console.log('Exited fullscreen')
          })
          .catch((err) => {
            console.error('Failed to exit fullscreen:', err)
          })
      } else {
        screenfull
          .request()
          .then(() => {
            console.log('Entered fullscreen')
          })
          .catch((err) => {
            console.error('Failed to enter fullscreen:', err)
          })
      }
    } else {
      console.error('Fullscreen mode is not supported.')
    }
  }

  window.addEventListener('orientationchange', handleResetFullScreen)
  return () => {
    window.removeEventListener('orientationchange', handleResetFullScreen)
  }
}
