import _ from 'lodash'
import { REGEX_EMOJI } from 'src/constants'
import { openDialog } from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { handleRelationObj } from 'src/redux/slices/eLearning/learning/events'
import { postCourseBatchQuestion } from 'src/services/eLearning/learning'
import { dialogContentObj } from 'src/modules/ELearning/components/DialogContent/model'
import { getLearningState } from '../../events'

export const handleSubmit = (values, resetForm) => (dispatch) => {
  dispatch(
    openDialog({
      title: 'ยืนยันการส่งคำถาม',
      message: ['คุณต้องการส่งคำถามเกี่ยวกับหลักสูตรนี้?'],
      buttonRight: {
        handleClick: () => dispatch(handleSubmitConfirm(values, resetForm)),
      },
      buttonLeft: { label: 'ยกเลิก' },
    })
  )
}

export const handleSubmitConfirm = (values, resetForm) => async (dispatch) => {
  const {
    course,
    eLearningCourseBatch,
    eLearningCourseBatchLearner,
    selectedLesson,
  } = getLearningState()
  const eLearningCourse = handleRelationObj(course)
  const eLearningLessonRefModule = _.get(
    selectedLesson,
    'eLearningLessonRefModule',
    {}
  )
  const body = {
    ...values,
    eLearningCourse,
    eLearningCourseBatch,
    eLearningLessonRefModule,
    questionBy: eLearningCourseBatchLearner,
  }
  dispatch(startLoading())
  const response = await dispatch(postCourseBatchQuestion(body))
  dispatch(stopLoading())
  resetForm()

  if (_.isEmpty(response.error)) {
    dispatch(
      openDialog({
        ...dialogContentObj,
        iconType: 'done',
        title: 'ขอบคุณสำหรับคำถาม จะมีเจ้าหน้าที่ตอบกลับโดยเร็วที่สุด',
      })
    )
  }
}

export const handlePreventEmoji = (e) => {
  const key = e.nativeEvent.data
  const isEmoji = REGEX_EMOJI.test(key)
  if (isEmoji) {
    e.target.value = e.target.value.replace(REGEX_EMOJI, '')
  }
  return e.target.value
}
