import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import { initialState } from '../../../redux/slices/manageProspect/model'
import Template from '../../../components/Template'

import Header from './Header'
import Content from './Content'
import Register from './Register'
import Examination from './Examination'

import { validateSchema } from './validateSchema'
import {
  fetchDataById,
  fetchDataById2,
  handleSubmit,
  handleChangeType,
  fetchOption,
} from './events'
import {
  setClearData,
  setChangeInfo,
  setSelectActionType,
} from '../../../redux/slices/manageProspect'
import { StyledDialogContent } from '../Styled'
import { listType } from './model'
import {
  setDefaultSort,
  setOrder,
  setDefaultSortId,
} from 'src/redux/slices/table2'
import { BoxDataEmpty } from './Register/Styled'

export const ContentDialog = () => {
  const dispatch = useDispatch()
  const { selectActionType } = useSelector(
    (state) => state.manageProspect,
    shallowEqual
  )
  useEffect(() => {
    return () => {
      dispatch(setSelectActionType('enroll'))
    }
  }, [])
  return (
    <StyledDialogContent sx={{ mt: 3 }}>
      <FormControl>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2">กรุณาเลือกรูปแบบการสมัคร</Typography>
          <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>
            *
          </Typography>
        </Box>
        <RadioGroup sx={{ ml: 1 }} name="type" value={selectActionType}>
          <FormControlLabel
            value="enroll"
            control={<Radio />}
            variant="body1"
            label="สมัครเข้าอบรม"
            onChange={() => dispatch(setSelectActionType('enroll'))}
          />

          <FormControlLabel
            value="eExam"
            variant="body1"
            control={<Radio />}
            label="สมัครสอบ"
            onChange={() => dispatch(setSelectActionType('eExam'))}
          />
        </RadioGroup>
      </FormControl>
    </StyledDialogContent>
  )
}

const Index = ({ caseType }) => {
  const { id: uuid } = useParams()
  const [user] = useLocalStorage('user')
  const dispatch = useDispatch()
  const { table, search } = useSelector((state) => state.table, shallowEqual)
  const { table: table2, search: search2 } = useSelector(
    (state) => state.table2,
    shallowEqual
  )
  const { limit, order, page, sort } = table
  const { limit: limit2, order: order2, page: page2, sort: sort2 } = table2
  const { searchText } = search
  const { searchText: searchText2 } = search2
  const [isInitial, setIsInitial] = useState(true)

  useMemo(() => {
    dispatch(setClearData())
    localStorage.removeItem('prospect')
    setIsInitial(true)
  }, [])

  useEffect(() => {
    if (caseType === 'edit' || caseType === 'view') {
      dispatch(fetchDataById({ table, searchText, uuid, method: 'fetch' }))
    } else {
      dispatch(fetchOption())
      dispatch(
        setChangeInfo({
          key: 'division',
          value: _.get(user, 'agentProfile.division', ''),
        })
      )
    }
    dispatch(handleChangeType({ caseType, userType: user.userType }))
  }, [limit, order, page, sort, caseType])

  useEffect(() => {
    if (caseType === 'edit' || caseType === 'view') {
      if (isInitial) {
        dispatch(setDefaultSortId('registerDateTime'))
        dispatch(setDefaultSort('registerDateTime'))
        dispatch(
          setOrder({
            order: 'desc',
            sort: 'registerDateTime',
          })
        )
        setIsInitial(false)
      } else {
        dispatch(
          fetchDataById2({
            table: table2,
            searchText: searchText2,
            uuid,
            method: 'fetch',
          })
        )
      }
    }
  }, [limit2, order2, page2, sort2, caseType, isInitial])

  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')
  const staffType = _.get(user, 'staffProfile.type', '')

  return (
    <>
      <Formik
        initialValues={initialState.information}
        validationSchema={validateSchema(abbreviation, staffType)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(value, { setErrors }) => {
          dispatch(
            handleSubmit({
              ...value,
              caseType,
              setErrors,
              unitNumber: user.unitNumber,
              isSteal: false,
            })
          )
        }}
      >
        <Form>
          <Header />
          <Template noMinHeight>
            <Content caseType={caseType} />
          </Template>
        </Form>
      </Formik>
      {caseType !== listType.create && uuid != user.uuid ? (
        <>
          <Template noMinHeight>
            <Register />
          </Template>

          <Template noMinHeight>
            <Examination />
          </Template>
        </>
      ) : (
        <BoxDataEmpty />
      )}
    </>
  )
}

export default Index
