export const formatData = (status, canceledBy) => {
  let result = {
    label: '-',
    color: 'text.primary',
  }
  switch (status) {
    case 'TIMEOUT':
      result = {
        label: 'หมดระยะเวลาอัปโหลด',
        color: 'error.main',
      }
      break
    case 'WAITING_DOCUMENT':
      result = {
        label: 'รออัปโหลดเอกสาร',
        color: 'other.ratingActive',
      }
      break
    case 'WAITING_CHECK':
      result = {
        label: 'รอตรวจสอบ',
        color: 'other.ratingActive',
      }
      break
    case 'REJECTED_DOCUMENT':
      result = {
        label: 'เอกสารไม่ผ่าน',
        color: 'error.main',
      }
      break
    case 'INELIGIBLE':
      result = {
        label: 'ไม่มีสิทธิ์เรียน',
        color: 'error.main',
      }
      break
    case 'ELIGIBLE':
      result = {
        label: 'มีสิทธิ์เรียน',
        color: 'other.ratingActive',
      }
      break
    case 'CANCEL':
      result = {
        label: 'ยกเลิกโดยคุณ',
        color: 'error.main',
      }
      break
    case 'CANCELED':
      result = {
        label: `ยกเลิกโดย ${canceledBy}`,
        color: 'error.main',
      }
      break
    case 'CANCELED_MA':
      result = {
        label: `ยกเลิกโดย ${canceledBy}`,
        color: 'error.main',
      }
      break
    case 'CLASS_CLOSED':
      result = {
        label: 'จบคลาส',
        color: 'success.main',
      }
      break

    case 'WAITING_CLASS_TO_START':
      result = {
        label: 'รอเข้าอบรม',
        color: 'text.lightGray',
      }
      break

    case 'CLASS_IN_PROGRESS':
      result = {
        label: 'ระหว่างอบรม',
        color: 'blue.deepDapphire',
      }
      break
    case 'CLASS_CLOSED_PASS':
      result = {
        label: 'จบคลาส (ผ่าน)',
        color: 'success.main',
      }
      break

    case 'CLASS_CLOSED_NOT_PASS':
      result = {
        label: 'จบคลาส (ไม่ผ่าน)',
        color: 'error.main',
      }
      break
    case 'ERROR':
      result = {
        label: 'ผิดพลาด',
        color: 'error.main',
      }
      break
    case 'NO_RESULT':
      result = {
        label: 'ยังไม่มีผล',
        color: 'text.lightGray',
      }
      break
    case 'CLASS_CANCELED':
      result = {
        label: 'ยกเลิกคลาส',
        color: 'error.main',
      }
      break
    case 'WAITING_ANNOUNCE':
      result = {
        label: 'รอประกาศสิทธิ์',
        color: 'other.ratingActive',
      }
      break
    case 'WAITING_CONFIRM':
      result = {
        label: 'รอคอนเฟิร์มตัวแทน',
        color: 'other.ratingActive',
      }
      break
  }

  return result
}
