/* eslint-disable no-misleading-character-class */
/* eslint-disable no-useless-escape */
import * as yup from 'yup'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

export const validateSchema = (type) => {
  const { id } = useParams()
  const { information } = useSelector(
    (state) => state.manageProspect,
    shallowEqual
  )
  return yup.object({
    information: yup.object().shape({
      prefixTH: yup.string('กรุณาเลือก').required('กรุณาเลือก'),
      firstNameTH: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test(
          'test-regex',
          'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
          function (value) {
            if (_.isEmpty(_.defaultTo(value, '').trim())) return false
            const regexTH = /[a-zA-Z]/g
            return !regexTH.test(value)
          }
        )
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      lastNameTH: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test(
          'test-regex',
          'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
          function (value) {
            if (_.isEmpty(_.defaultTo(value, '').trim())) return false
            const regexTH = /[a-zA-Z]/g
            return !regexTH.test(value)
          }
        )
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      prefix: yup.string('กรุณาเลือก').required('กรุณาเลือก'),
      firstName: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test('test-regex', 'กรุณากรอกให้ถูกต้อง', function (value) {
          if (_.isEmpty(_.defaultTo(value, '').trim())) return false
          const regex = /^[0-9a-zA-Z\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
          return regex.test(value)
        })
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      lastName: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test('test-regex', 'กรุณากรอกให้ถูกต้อง', function (value) {
          if (_.isEmpty(_.defaultTo(value, '').trim())) return false
          const regex = /^[0-9a-zA-Z\s\][{}~`<>|,?'";:!@#$%^&*)(+=._-]+$/g
          return regex.test(value)
        })
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      mobileNo: yup
        .string('กรุณากรอกให้ถูกต้อง')
        .nullable(true)
        .test('mobileNo-value', 'กรุณากรอกให้ถูกต้อง', function (value) {
          if (type !== 'CS') {
            return String(value).replace(/[^0-9\.]+/g, '').length < 10
              ? false
              : true
          } else {
            return !_.isEmpty(String(value).replace(/[^0-9\.]+/g, '')) &&
              String(value).replace(/[^0-9\.]+/g, '').length < 10
              ? false
              : true
          }
        }),
      email: yup
        .string('กรุณากรอก')
        .required('กรุณากรอก')
        .test('email-value', 'กรุณากรอกให้ถูกต้อง', function (value) {
          const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
          return regex.test(value)
        })
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      dateOfBirth: yup
        .string('กรุณากรอกวัน เดือน ปี เกิดให้ถูกต้อง')
        .nullable()
        .test(
          'check edit Birth',
          'กรุณากรอกวัน เดือน ปี เกิดให้ถูกต้อง',
          function (value) {
            console.log(information)

            if (
              information.consent === 'CONSENT' ||
              information?.staffProfile?.type === 'NON_ACADEMY'
            )
              if (value === null || value === 'Invalid Date') return false
            return true
          }
        ),

      idCardNo: yup
        .string('กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง')
        .nullable()
        .test(
          'check idCard',
          'กรุณากรอกเลขบัตรประชาชนให้ถูกต้อง',
          function (value) {
            const user = JSON.parse(localStorage.getItem('user'))
            if (user.uuid === id) {
              if (value === null || _.size(value) !== 13) return false
            }
            return true
          }
        ),
      institution: yup
        .string()
        .nullable()
        .max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      between: yup.string().nullable().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      branch: yup.string().nullable().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
      gpa: yup.string().nullable().max(255, 'กรอกได้ไม่เกิน 255 ตัวอักษร'),
    }),
  })
}
