import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { setDisableButton } from '../../../redux/slices/profile'
import { prefixTHtoEN } from '../../../redux/slices/profile/model'
import TextInput from '../../Input/TextInput'
import Select from '../../Input/Select'
import InputMask from '../../Input/InputMask'
import DatePicker from '../../DatePicker'
import { isMediaWidthMoreThan, validateNameEN } from '../../../utils/lib'
import { optionPrefixEn, optionPrefixTh, userType } from './model'
import { StyledFormRow, StyledFormWrapper } from './Styled'
import AlertDialog from '../AlertDialog'
import { handleInputFormat, fetchDataById } from '../events'

const UpdateProfileForm = () => {
  const [user, setUser] = useLocalStorage('user')
  const isAgent = user.userType === userType.AGENT
  const isCS =
    _.get(user, 'distributionChannel.abbreviation', '') === userType.CS

  const staffType = _.get(user, 'staffProfile.type', null)
  const nonAcademy = staffType === 'NON_ACADEMY'
  const isStaff = user.userType === userType.STAFF && !nonAcademy
  const isProspect = user.userType === userType.PROSPECT
  const uuid = user.prospectProfile?.uuid
  const { values, errors, handleChange, setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const btnDisabled = useSelector(
    (state) => state.profile.btnDisabled,
    shallowEqual
  )
  const profileData = useSelector((state) => state.profile.data, shallowEqual)

  const isFullFormInProgress = !_.isEmpty(profileData)

  useEffect(() => {
    const updatedUser = {
      ...user,
      isFullFormInProgress: !_.isEmpty(profileData),
    }
    setUser(updatedUser)
  }, [profileData])

  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    if (uuid) {
      dispatch(fetchDataById({ uuid }))
    }
  }, [uuid, dispatch])

  useEffect(() => {
    dispatch(setDisableButton({ values, isProspect, isCS, nonAcademy }))
  }, [values])

  const isDMS = _.get(user, 'prospectProfile.isDMS', false)

  return (
    <StyledFormWrapper>
      <img src="/icon/axa_help.svg" />
      <Typography variant="h4" color="primary">
        โปรดอัปเดตข้อมูล
      </Typography>
      <Select
        id="prefixTH"
        name="prefixTH"
        labelText="คำนำหน้าชื่อ"
        type="basic"
        required={!isCS}
        disabled={isStaff}
        options={optionPrefixTh}
        showTextError={false}
        value={_.get(values, 'prefixTH', '')}
        handleChange={(e) => {
          setFieldValue('prefixTH', e.target.value)
          setFieldValue('prefix', prefixTHtoEN[e.target.value])
        }}
      />
      <StyledFormRow>
        <TextInput
          type="basic"
          id="firstNameTH"
          labelText="ชื่อ"
          isShowTextError={_.get(errors, 'firstNameTH', '')}
          textError={_.get(errors, 'firstNameTH', '')}
          required
          disabled={!nonAcademy}
          value={_.get(values, 'firstNameTH', '')}
          onChange={handleChange}
        />
        <TextInput
          type="basic"
          id="lastNameTH"
          labelText="นามสกุล"
          isShowTextError={_.get(errors, 'lastNameTH', '')}
          textError={_.get(errors, 'lastNameTH', '')}
          required={!isCS}
          disabled={!nonAcademy}
          value={_.get(values, 'lastNameTH', '')}
          onChange={handleChange}
        />
      </StyledFormRow>
      {!isAgent && (
        <>
          <Divider sx={{ width: '100%', borderStyle: 'dashed' }} />
          <Select
            id="prefixEN"
            name="prefixEN"
            labelText="Title Name"
            type="basic"
            required
            disabled
            options={optionPrefixEn}
            showTextError={false}
            value={_.get(values, 'prefix', '')}
          />
          <StyledFormRow id="name">
            <TextInput
              type="basic"
              name="firstName"
              id="firstName"
              labelText="First Name"
              isShowTextError={_.get(errors, 'firstName', '')}
              required
              disabled={isStaff || isFullFormInProgress}
              textError={_.get(errors, 'firstName', '')}
              value={validateNameEN(_.get(values, 'firstName', ''), values)}
              onChange={handleChange}
            />
            <TextInput
              type="basic"
              name="lastName"
              id="lastName"
              labelText="Last Name"
              isShowTextError={_.get(errors, 'lastName', '')}
              required
              disabled={isStaff || isFullFormInProgress}
              textError={_.get(errors, 'lastName', '')}
              value={validateNameEN(_.get(values, 'lastName', ''), values)}
              onChange={handleChange}
            />
          </StyledFormRow>
          {((isProspect && isDMS) || nonAcademy) && (
            <>
              <Divider sx={{ width: '100%', borderStyle: 'dashed', mt: -3 }} />
              {nonAcademy && (
                <StyledFormRow>
                  <InputMask
                    sx={{ height: 50 }}
                    id="mobileNo"
                    name="mobileNo"
                    labelText="เบอร์โทรศัพท์"
                    placeholder=""
                    maskChar={null}
                    mask="999-999-9999"
                    required
                    onChange={(e) => setFieldValue('mobileNo', e.target.value)}
                    value={_.get(values, 'mobileNo', '')}
                    isShowTextError={_.get(errors, 'mobileNo', '')}
                    textError={_.get(errors, 'mobileNo', '')}
                  />
                  <DatePicker
                    labelText="วัน เดือน ปี เกิด"
                    required
                    onChange={(e) => setFieldValue('dateOfBirth', e)}
                    placeholder="00/00/0000"
                    data-testid="date-picker"
                    boxSx={{
                      '& .MuiTextField-root': {
                        '& svg': {
                          color: '#00008F',
                        },
                      },
                    }}
                    value={_.get(values, 'dateOfBirth', null)}
                    hideTextError={!_.get(errors, 'dateOfBirth', '')}
                    textError={_.get(errors, 'dateOfBirth', '')}
                  />
                </StyledFormRow>
              )}
              <StyledFormRow id="idCardNo">
                <TextInput
                  type="basic"
                  name="idCardNo"
                  id="idCardNo"
                  labelText="หมายเลขบัตรประจำตัวประชาชน "
                  isShowTextError={!isFullFormInProgress}
                  required
                  disabled={isFullFormInProgress}
                  textError={_.get(errors, 'idCardNo', '')}
                  value={_.get(values, 'idCardNo', '')}
                  inputProps={{
                    onInput: handleInputFormat,
                  }}
                  onChange={handleChange}
                />
              </StyledFormRow>
            </>
          )}
        </>
      )}

      <Typography
        id="remark"
        variant="body2"
        color="primary"
        sx={{ textAlign: 'center' }}
      >
        หากต้องการเปลี่ยนแปลงข้อมูล
        {!isNotSm && <br />}
        กรุณาติดต่อต้นสังกัด
      </Typography>
      <Button
        size="l"
        variant="contained"
        type="submit"
        sx={{ width: '100%', my: 3 }}
        disabled={btnDisabled}
      >
        ยืนยัน
      </Button>
      <AlertDialog />
    </StyledFormWrapper>
  )
}

export default UpdateProfileForm
