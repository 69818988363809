import React from 'react'
import _ from 'lodash'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useLocalStorage from '@rehooks/local-storage'
import { isMediaWidthMoreThan } from '../../../../../utils/lib'
import TextInput from '../../../../../components/Input/TextInput'
import { FormButtons } from '../../Header'
import { handleEducational, checkDataEmpty, handleChangeInfo } from '../events'
import BasicSelect from '../../../../../components/Input/Select'

import { BoxTextLabel } from '../'
import {
  BoxCard,
  BoxContentLabel,
  BoxGroupLeft,
  BoxContentRight,
  BoxInput,
} from '../Styled'

const Index = () => {
  const dispatch = useDispatch()
  const { errors } = useFormikContext()

  const [user] = useLocalStorage('user')
  const { information, type, qualificationOptions } = useSelector(
    (state) => ({
      information: state.manageProspect.information,
      type: state.manageProspect.type,
      qualificationOptions: state.eExamination.options.qualificationOptions,
    }),
    shallowEqual
  )
  // qualificationOptions: state.eExamination.options.qualificationOptions,

  const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')
  const agentUnitName = _.get(user, 'agentProfile.agentUnitName', '')

  const staffType = _.get(user, 'staffProfile.type', '')

  const unitNumber = `${user.unitNumber} | ${agentUnitName}`

  const division = _.get(information, 'division', {})
  const divisionText = _.isEmpty(division) ? '-' : division
  const isNotSm = isMediaWidthMoreThan('sm')

  return (
    <BoxContentRight>
      {staffType !== 'NON_ACADEMY' && (
        <BoxCard>
          <Box sx={{ m: 3 }}>
            <Typography variant="h6" color="primary.main" sx={{ mb: 2 }}>
              ข้อมูลการทำงาน
            </Typography>
            <BoxContentLabel>
              <BoxTextLabel
                labelLeft={
                  abbreviation === 'CS' ? 'Broker Number' : 'Unit Number'
                }
                labelRight={unitNumber}
              />
              <BoxTextLabel
                labelLeft="Temp Code"
                labelRight={_.get(information, 'userCode', null) ?? '-'}
                isFirst={true}
              />
            </BoxContentLabel>
            {abbreviation === 'CS' && (
              <Box sx={{ mt: 2 }}>
                {type != 'view' ? (
                  <>
                    <TextInput
                      sx={{ height: 50 }}
                      id="division"
                      name="division"
                      isShowTextError={false}
                      labelText="แผนก / ฝ่าย"
                      type="basic"
                      maxlength={255}
                      onChange={(e) =>
                        dispatch(
                          handleChangeInfo({
                            key: 'division',
                            value: _.get(e, 'target.value', ''),
                          })
                        )
                      }
                      value={_.get(information, 'division', '')}
                    />
                    <Typography variant="body2" color="text.secondary">
                      ระบุได้ไม่เกิน 255 ตัวอักษร
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ wordBreak: 'break-all' }}>
                    <Typography variant="body1" color="text.secondary">
                      แผนก/ฝ่าย
                    </Typography>
                    <Typography variant="body2">{divisionText}</Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </BoxCard>
      )}
      <BoxCard sx={{ height: 'auto' }}>
        <Box sx={{ m: 3 }}>
          <Typography variant="h6" color="primary.main" sx={{ mb: 2 }}>
            ประสบการณ์
          </Typography>
          {type !== 'view' ? (
            <Box>
              <BoxInput>
                <TextInput
                  sx={{ height: 50 }}
                  id="institution"
                  name="institution"
                  labelText="สถานศึกษา (มหาวิทยาลัย)"
                  type="basic"
                  onChange={(e) =>
                    dispatch(
                      handleEducational({
                        key: 'institution',
                        value: _.get(e, 'target.value', ''),
                      })
                    )
                  }
                  textError={_.get(errors, 'information.institution', '')}
                  value={_.get(information, 'institution', '')}
                />
              </BoxInput>

              <BoxGroupLeft>
                <BoxInput>
                  <TextInput
                    sx={{ height: 50 }}
                    id="between"
                    name="between"
                    labelText="ระหว่างปี"
                    type="basic"
                    onChange={(e) =>
                      dispatch(
                        handleEducational({
                          key: 'between',
                          value: _.get(e, 'target.value', ''),
                        })
                      )
                    }
                    textError={_.get(errors, 'information.between', '')}
                    value={_.get(information, 'between', '')}
                  />
                </BoxInput>
                <BoxInput>
                  <BasicSelect
                    selectSx={{
                      height: 50,
                      background: '#FFFFFF',
                      '& .MuiSelect-icon': { color: '#00008F' },
                    }}
                    placeholder="กรุณาเลือก"
                    labelText="วุฒิการศึกษา"
                    isShowTextError={false}
                    value={_.get(information, 'qualification', '')}
                    disabled={false}
                    handleChange={(e) =>
                      dispatch(
                        handleEducational({
                          key: 'qualification',
                          value: _.get(e, 'target.value', ''),
                        })
                      )
                    }
                    options={
                      qualificationOptions || [
                        {
                          value: _.get(information, 'qualification', ''),
                          label: _.get(information, 'qualification', ''),
                        },
                      ]
                    }
                  />
                </BoxInput>
              </BoxGroupLeft>
              <BoxGroupLeft>
                <BoxInput>
                  <TextInput
                    sx={{ height: 50 }}
                    id="branch"
                    name="branch"
                    labelText="สาขา"
                    type="basic"
                    onChange={(e) =>
                      dispatch(
                        handleEducational({
                          key: 'branch',
                          value: _.get(e, 'target.value', ''),
                        })
                      )
                    }
                    textError={_.get(errors, 'information.branch', '')}
                    value={_.get(information, 'branch', '')}
                  />
                </BoxInput>

                <BoxInput>
                  <TextInput
                    sx={{ height: 50 }}
                    id="GPA"
                    name="GPA"
                    labelText="เกรดเฉลี่ย"
                    type="basic"
                    onChange={(e) =>
                      dispatch(
                        handleEducational({
                          key: 'gpa',
                          value: _.get(e, 'target.value', ''),
                        })
                      )
                    }
                    textError={_.get(errors, 'information.gpa', '')}
                    value={_.get(information, 'gpa', '')}
                  />
                </BoxInput>
              </BoxGroupLeft>
            </Box>
          ) : (
            <Box>
              <BoxContentLabel>
                <BoxTextLabel
                  isView={false}
                  labelLeft="สถานศึกษา (มหาวิทยาลัย)"
                  labelRight={checkDataEmpty(
                    _.get(information, 'institution', '')
                  )}
                />
                <BoxTextLabel
                  isView={false}
                  labelLeft="วุฒิการศึกษา"
                  labelRight={checkDataEmpty(
                    qualificationOptions.find(
                      (ele) =>
                        ele.value == _.get(information, 'qualification', '')
                    )?.label || _.get(information, 'qualification', '')
                  )}
                />
              </BoxContentLabel>

              <BoxContentLabel>
                <BoxTextLabel
                  isView={true}
                  labelLeft="ระหว่าง"
                  labelRight={checkDataEmpty(
                    _.get(information, 'between', '-')
                  )}
                />
                <BoxTextLabel
                  isView={true}
                  labelLeft="สาขา"
                  labelRight={checkDataEmpty(_.get(information, 'branch', '-'))}
                />
              </BoxContentLabel>
              <BoxTextLabel
                isView={true}
                labelLeft="เกรดเฉลี่ย"
                labelRight={checkDataEmpty(_.get(information, 'gpa', '-'))}
              />
              <Divider sx={{ mt: 2, mb: 0.5 }} />
            </Box>
          )}
        </Box>
      </BoxCard>
      {!isNotSm && (
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <FormButtons type={type} information={information} />
        </Box>
      )}
    </BoxContentRight>
  )
}

export default Index
