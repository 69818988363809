import React from 'react'
import _ from 'lodash'
import { store } from 'src/App'
import {
  openDialog as openEnrollDialog,
  closeDialog as closeEnrollDialog,
  setChangeBody,
  setChangeCamera,
  setDisableSubmit,
} from 'src/redux/slices/eLearning/enroll'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { dataURLtoFile, getQueryParams } from 'src/utils/lib'
import {
  closeDialog,
  openDialog,
  openErrorDialog,
} from 'src/redux/slices/alertDialog'
import {
  postEnrollIdCard,
  postVerifyIdCard,
} from 'src/services/eLearning/enroll'
import { ENROLL_INPUT_TYPE } from 'src/constants/eLearning'
import { dialogContentObj } from 'src/modules/ELearning/components/DialogContent/model'
import { getEnrollState } from '../../events'
import { openCameraErrorDialog } from '../events'
import CameraDialog from './CameraDialog'
import QualityFailDialog from './QualityFailDialog'

export const handleOpenCameraDialog = () => {
  store.dispatch(setChangeCamera({ key: 'isOpen', value: false }))
  store.dispatch(openEnrollDialog({ content: <CameraDialog /> }))
}

export const handleCameraError = (error) => {
  if (error.toString().includes('NotFoundError')) {
    openCameraErrorDialog()
  }
}

export const handleChangeImage = () => {
  store.dispatch(setChangeBody({ key: 'faceImage', value: null }))
  store.dispatch(setChangeBody({ key: 'idCardImage', value: null }))
  store.dispatch(setChangeCamera({ key: 'idCardImage', value: null }))
  store.dispatch(setDisableSubmit(false))
}

export const handleSubmitIdCard = (file, typeInput) => async (dispatch) => {
  const formData = new FormData()
  formData.append('file', file)

  dispatch(startLoading())
  const response = await dispatch(postVerifyIdCard(formData))

  if (_.isEmpty(response.error)) {
    const data = _.get(response.payload, 'data', {})
    const idCardImageKey = _.get(data, 'key', '')
    const learnerUuid = getQueryParams('learner')
    const state = getEnrollState()
    const body = {
      eLearningCourseBatch: state.body.batch,
      eLearningCourseBatchLearner: { uuid: learnerUuid },
      idCardImageKey,
      typeInput,
    }

    const resEnroll = await dispatch(postEnrollIdCard(body))
    dispatch(stopLoading())

    if (_.isEmpty(resEnroll.error)) {
      dispatch(setChangeBody({ key: 'faceImage', value: null }))

      const quality = _.get(data, 'quality', {})
      const isBrightnessFail = quality.brightness < 80
      const isSharpnessFail = quality.sharpness < 20
      if (isBrightnessFail || isSharpnessFail) {
        const params = { idCardImageKey, isBrightnessFail, isSharpnessFail }
        handleQualityFail(typeInput, params)
      } else {
        dispatch(setChangeBody({ key: 'idCardImage', value: idCardImageKey }))
        dispatch(setChangeBody({ key: 'typeInput', value: typeInput }))
        dispatch(handleUploadSuccess(typeInput))
      }
    } else {
      dispatch(closeEnrollDialog())
      dispatch(openErrorDialog())
    }
  } else {
    dispatch(stopLoading())
    dispatch(closeEnrollDialog())
    const errorObj = _.get(response, 'meta.response.data', {})
    const constraints = _.get(errorObj, 'constraints', {})
    let message = 'อัปโหลดรูปภาพล้มเหลว กรุณาลองใหม่อีกครั้ง'
    if (constraints.value) message = constraints.value
    if (errorObj.message?.includes('format')) message = 'นามสกุลไฟล์ไม่ถูกต้อง'
    dispatch(openErrorDialog({ ...constraints, message }))
  }
}

export const handleUploadSuccess = (typeInput) => (dispatch) => {
  const title =
    typeInput === ENROLL_INPUT_TYPE.UPLOAD
      ? 'อัปโหลดรูปบัตรสำเร็จ'
      : 'ถ่ายรูปบัตรสำเร็จ'
  dispatch(
    openDialog({
      ...dialogContentObj,
      iconType: 'done',
      title,
      buttonRight: {
        handleClick: () => {
          dispatch(closeDialog())
          dispatch(closeEnrollDialog())
        },
      },
    })
  )
}

export const handleCapture = (camRef, field) => async (dispatch) => {
  const value = camRef.current.getScreenshot()
  const payload = { key: field, value }
  dispatch(setChangeCamera(payload))

  const file = dataURLtoFile(value, `${field}.jpeg`)
  await dispatch(handleSubmitIdCard(file, ENROLL_INPUT_TYPE.CAPTURE))
}

export const handleQualityFail = (typeInput, params) => {
  const { idCardImageKey } = params
  store.dispatch(closeEnrollDialog())
  store.dispatch(
    openDialog({
      ...dialogContentObj,
      maxWidth: 'md',
      iconType: 'info',
      title: 'ภาพบัตรมีความคมชัดต่ำ',
      message: ['ระบบแนะนำให้เปลี่ยนการตั้งค่ากล้องตามความเหมาะสม'],
      content: <QualityFailDialog {...params} />,
      buttonLeft: {
        variant: 'contained',
        label: 'ลองอีกครั้ง',
        handleClick: () => handleTryCaptureAgain(typeInput),
      },
      buttonRight: {
        variant: 'outlined',
        label: 'ยืนยันใช้ภาพบัตร',
        handleClick: () => handleSubmitThisImage(idCardImageKey, typeInput),
      },
    })
  )
}

export const handleTryCaptureAgain = (typeInput) => {
  const isCapture = typeInput === ENROLL_INPUT_TYPE.CAPTURE

  if (isCapture) {
    store.dispatch(
      openEnrollDialog({
        content: <CameraDialog isShowTooltip />,
      })
    )
    store.dispatch(setChangeCamera({ key: 'isOpen', value: true }))
  }

  store.dispatch(setChangeBody({ key: 'idCardImage', value: null }))
  store.dispatch(setChangeCamera({ key: 'idCardImage', value: null }))
  store.dispatch(setDisableSubmit(false))
  store.dispatch(closeDialog())
}

export const handleSubmitThisImage = (idCardImageKey, typeInput) => {
  store.dispatch(setChangeBody({ key: 'idCardImage', value: idCardImageKey }))
  store.dispatch(setChangeBody({ key: 'typeInput', value: typeInput }))
  store.dispatch(closeDialog())
  store.dispatch(closeEnrollDialog())
}
