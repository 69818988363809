import React from 'react'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import DialogContent from 'src/modules/ELearning/components/DialogContent'
import { StyledColumn, StyledRow } from './Styled'

const ErrorListContent = () => {
  return (
    <DialogContent>
      <Column sx={{ mt: 3, gap: 2, alignItems: 'center' }}>
        <StyledRow>
          <img src="/icon/ic_face_not_match.svg" />
          <StyledColumn>
            <Typography variant="body1b" color="text.secondary">
              ใบหน้าไม่ตรงกับรูปในบัตร
            </Typography>
            <Typography variant="body2" color="text.secondary">
              กรุณาตรวจสอบทรงผมและมุมมองการหันเข้าหากล้อง
              เพื่อให้รูปของท่านใกล้เคียงรูปใบหน้าบนบัตรมากที่สุด
            </Typography>
          </StyledColumn>
        </StyledRow>

        <StyledRow>
          <img src="/icon/ic_brightness.svg" />
          <StyledColumn>
            <Typography variant="body1b" color="text.secondary">
              แสงสว่างไม่เพียงพอในขณะถ่ายรูปยืนยันตัวตน
            </Typography>
            <Typography variant="body2" color="text.secondary">
              กรุณาตรวจสอบว่ามุมที่ใช้ในการถ่ายรูปมีแสงตกกระทบบนใบ
              หน้าเพียงพอหรือไม่
            </Typography>
          </StyledColumn>
        </StyledRow>

        <StyledRow>
          <img src="/icon/ic_duplicate_face.svg" />
          <StyledColumn>
            <Typography variant="body2b" color="text.secondary">
              พบมากกว่าหนึ่งใบหน้าในกล้องขณะถ่ายรูปยืนยันตัวตน
            </Typography>
            <Typography variant="body2" color="text.secondary">
              กรุณาจัดมุมถ่ายรูปที่สามารถเห็นเพียงใบหน้าของท่านเท่านั้น
            </Typography>
          </StyledColumn>
        </StyledRow>
      </Column>
    </DialogContent>
  )
}

export default ErrorListContent
