import React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { isMediaWidthMoreThan } from 'src/utils/lib'

import packageJson from '../../../package.json'
import {
  ColumnBox,
  ImageBox,
  StyledFooter,
  StyledFooterBottom,
  StyledFooterColumn,
  StyledFooterContainer,
  StyledFooterTop,
  TextBox,
} from './Styled'

const Footer = () => {
  return (
    <StyledFooter id="page-footer">
      <FooterTop />
      <FooterBottom />
    </StyledFooter>
  )
}

export default Footer

export const FooterTop = () => {
  const isLg = isMediaWidthMoreThan('lg')
  return (
    <StyledFooterContainer>
      <StyledFooterTop>
        <StyledFooterColumn gap="8px">
          <Typography variant="body1">
            Training and Education make the difference
          </Typography>
          <Typography variant="body1">
            เพราะเราเชื่อว่า... การศึกษาและ{isLg && <br />}การฝึกอบรม
            {!isLg && <br />}สร้างความแตกต่าง
          </Typography>
        </StyledFooterColumn>
        <StyledFooterColumn gap="8px">
          <Typography variant="h6">หลักสูตรเรียน</Typography>
          <Link href="/course">หลักสูตรทั้งหมด</Link>
          <Link href="/course">หลักสูตรแนะนำ</Link>
          <Link
            href="https://ktaxa-ipro.force.com/iPro/s/?language=th&t=1673234802739"
            target="_blank"
          >
            หลักสูตรออนไลน์ (E-Learning iPro)
          </Link>
        </StyledFooterColumn>
        <StyledFooterColumn gap="8px">
          <Typography variant="h6">QUICK ACCESS</Typography>
          <Link
            href="https://cloud.3dvista.com/hosting/7396321/0/index.htm?installed=true"
            target="_blank"
          >
            Virtual Tour
          </Link>
        </StyledFooterColumn>
        <StyledFooterColumn gap={16}>
          <Typography variant="h6">ช่องทางการติดต่อ</Typography>
          <ColumnBox sx={{ margin: '0 0 8px' }}>
            <Link href="/location">
              <TextBox>
                <ImageBox>
                  <img src="/icon/social/phone.svg" />
                </ImageBox>
                ติดต่อเรา
              </TextBox>
            </Link>
            <Link
              href="https://www.facebook.com/TrainingOperations"
              target="_blank"
            >
              <TextBox>
                <ImageBox>
                  <img src="/icon/social/facebook.svg" />
                </ImageBox>
                Training Operations
              </TextBox>
            </Link>
            <TextBox>
              <ImageBox>
                <img src="/icon/social/line.svg" />
              </ImageBox>
              <Link>@axa-academy</Link>
            </TextBox>
            <TextBox>
              <ImageBox>
                <img src="/icon/social/mail.svg" />
              </ImageBox>
              <Link>trainingoperations@krungthai-axa.co.th</Link>
            </TextBox>
          </ColumnBox>
          <Typography>
            จันทร์ - ศุกร์ เวลา 09:00 - 17:00 น. <br />
            เสาร์ เวลา 09:00 - 12:00 น.
          </Typography>
        </StyledFooterColumn>
      </StyledFooterTop>
    </StyledFooterContainer>
  )
}

export const FooterBottom = () => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const isProd = window.__env__.ENV === 'PROD'

  return (
    <>
      <StyledFooterBottom>
        <StyledFooterContainer
          sx={{
            display: 'flex',
            padding: '12px 0',
            justifyContent: isProd ? 'flex-end' : 'space-between',
          }}
        >
          {!isProd && (
            <Typography variant="body2" color="text.lightGray">
              v. {packageJson?.version}
            </Typography>
          )}
          <Typography
            variant={isNotSm ? 'body1' : 'body2'}
            color="text.mercury"
          >
            ลิขสิทธิ์ถูกต้อง © 2023 สถาบันฝึกอบรมและพัฒนาฝ่ายจัดจำหน่าย
          </Typography>
        </StyledFooterContainer>
      </StyledFooterBottom>
    </>
  )
}
