import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
import { Form, Formik } from 'formik'
import Box from '@mui/material/Box'
import { setInitialBody } from '../../redux/slices/profile'
import { StyledDialog } from '../AlertDialog/Styled'
import { handleSubmit } from './events'
import { schama } from './schema'
import UpdateProfileForm from './UpdateProfileForm'
import { StyledDialogWrapper } from './Styled'
import { userType } from './UpdateProfileForm/model'
import dayjs from 'dayjs'

const UpdateProfileDialog = ({ isOpen, onClose }) => {
  const [user, setUser] = useLocalStorage('user')
  const isAgent = user?.userType === userType.AGENT
  const isNonAcademy = _.get(user, 'staffProfile.type', null) === 'NON_ACADEMY'

  const dispatch = useDispatch()
  const body = useSelector((state) => state.profile.body, shallowEqual)

  useEffect(() => {
    dispatch(setInitialBody(user))
  }, [user])

  if (_.isEmpty(body)) return <></>

  return (
    <Box>
      <StyledDialog customWidth={717} maxWidth={'sm'} open={isOpen}>
        <StyledDialogWrapper>
          <Formik
            initialValues={{ ...body, isAgent, isNonAcademy }}
            validationSchema={schama}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => {
              dispatch(
                handleSubmit({
                  values: {
                    ...values,
                    dateOfBirth: values.dateOfBirth
                      ? dayjs(values.dateOfBirth).format('YYYY-MM-DD')
                      : '',
                  },
                  user,
                  setUser,
                  onClose,
                })
              )
            }}
          >
            <Form>
              <UpdateProfileForm />
            </Form>
          </Formik>
        </StyledDialogWrapper>
      </StyledDialog>
    </Box>
  )
}

export default UpdateProfileDialog
