import React from 'react'
import axios from 'axios'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AutoStories from '@mui/icons-material/AutoStories'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setFilterTotal,
  setCustomToolbar,
} from '../../redux/slices/table'
import { startLoading, stopLoading } from 'src/redux/slices/loading'

import { ContentDialog } from './'
import { downloadFile } from '../../services/util'
import { manageProspectCells } from '../../constants/manageProspect'
import {
  prospectFilter,
  prospectDownload,
  prospect,
  prospectResend,
} from '../../../src/utils/apiPath'
import _ from 'lodash'
import { deleteFromStorage } from '@rehooks/local-storage'
import * as slicesDialog from '../../../src/redux/slices/alertDialog'
import { store } from 'src/App'
import { handleSubmitSentMail } from './Form/events'
import { USER_TYPE } from '../EExamination/constant'
import { setSelectedList } from 'src/redux/slices/manageProspect'
import { postProspectDeleteMail } from '../../services/manageProspect'

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const body = {
    order: order.toUpperCase(),
    sort: formatData(sort),
    type: 'CUSTOM',
    list: selected,
  }

  await dispatch(
    downloadFile({
      url: prospectDownload,
      body: body,
      fileName: `รายการโปรไฟล์ทีม.xlsx`,
    })
  )
  dispatch(stopLoading())
}

export const formatData = (field) => {
  let text = ''
  switch (field) {
    case 'fullNameTH':
      text = 'firstNameTH'
      break
    case 'agent':
      text = 'refAgentCode'
      break
    case 'statusProspect':
      text = 'status'
      break
    default:
      text = field
      break
  }
  return text
}

export const onDelete = (selected, table, filterProp) => async (dispatch) => {
  dispatch(startLoading())
  await axios
    .delete(`${prospect}/${selected.uuid}`)
    .then(() => {
      dispatch(
        slicesDialog.openDialog({
          iconType: 'success',
          title: 'ลบสมาชิกออกจากทีมแล้ว',
          message: ['บันทึกเรียบร้อย'],
        })
      )
    })
    .then(() => {
      dispatch(
        postProspectDeleteMail({
          uuid: [selected.uuid],
        })
      )
    })
    .catch(() =>
      dispatch(
        slicesDialog.openDialog({
          iconType: 'error',
          title: 'ลบสมาชิกออกจากทีมล้มเหลว',
          message: ['โปรดลองใหม่อีกครั้ง?'],
        })
      )
    )
  dispatch(fetchDataList('fetch', table, '', filterProp))
  dispatch(stopLoading())
}

export const onResend = (selected, table, filterProp) => async (dispatch) => {
  dispatch(startLoading())
  await axios
    .put(`${prospectResend}/${selected.uuid}`)
    .then(() => {
      dispatch(
        slicesDialog.openDialog({
          iconType: 'success',
          title: 'ส่งอีเมลสำเร็จแล้ว',
          message: [
            `ระบบได้ทำการส่งอีเมลไปยัง ${selected.email}`,
            'เพื่อยินยอมการใช้ข้อมูลส่วนบุคคล',
          ],
        })
      )
    })
    .catch(() =>
      dispatch(
        slicesDialog.openDialog({
          iconType: 'error',
          title: 'ส่งอีเมลล้มเหลว',
          message: ['ระบบไม่สามารถส่งอีเมลได้ในขณะนี้', 'โปรดลองใหม่อีกครั้ง'],
        })
      )
    )
  dispatch(fetchDataList('fetch', table, '', filterProp))
  dispatch(stopLoading())
}

export const alertDialog =
  (setProspect, selectedList, setProspectDetail) => (dispatch) => {
    dispatch(
      slicesDialog.openDialog({
        isCloseDialog: false,
        disableButton: false,
        title: 'เลือกรูปแบบการสมัคร',
        content: <ContentDialog />,
        buttonLeft: {
          label: 'ยกเลิก',
          handleClick: () => {
            dispatch(slicesDialog.closeDialog())
            deleteFromStorage('prospect')
          },
        },
        buttonRight: {
          handleClick: () => {
            dispatch(
              handleClickEnroll(setProspect, selectedList, setProspectDetail)
            )
          },
        },
      })
    )
  }

export const fetchRegister =
  (selected, setProspect, selectedList, setProspectDetail) => (dispatch) => {
    dispatch(
      setCustomToolbar(
        <Button
          variant="text"
          startIcon={<AutoStories />}
          disabled={!selected.length}
          onClick={() => {
            deleteFromStorage('prospect')
            deleteFromStorage('prospectDetail')
            dispatch(alertDialog(setProspect, selectedList, setProspectDetail))
          }}
        >
          <Typography variant="button">สมัครเข้าอบรม</Typography>
        </Button>
      )
    )
  }

export const handleClickEnroll =
  (setProspect, selectedList, setProspectDetail) => (dispatch) => {
    const state = store.getState()
    const selectActionType = _.get(
      state.manageProspect,
      'selectActionType',
      'enroll'
    )
    const selectedRows = []
    const list = []
    _.map(selectedList, (row) => {
      if (row.status == 'ACTIVE' && row.userType == 'PROSPECT') {
        const userImageUrl = row?.examination?.userImage
          ? `${window.__env__.REACT_APP_FILE_URL}${row?.examination?.userImage}`
          : '/logo/staff_profile_small.png'
        const type = row?.examination?.type || 'TEMP'
        selectedRows.push({
          ...row.examination,
          uuid: row?.examination?.uuid || '',
          type: type,
          unitName: row?.examination?.unitName || '',
          unitNumber: row?.examination?.unitNumber || '',
          image: userImageUrl,
          firstNameTH: row?.examination?.firstNameTH || '',
          lastNameTH: row?.examination?.lastNameTH || '',
          prefix: row.examination.prefixTH || '',
          mobileNo: row?.examination?.mobileNo?.replace(/-/g, '') || '',
          email: row?.examination?.email || '',
          idCardNo: row?.examination?.idCardNo || '',
          isEdit: true,
          birthDate: row.examination.dateOfBirth || '',
          institution: row?.examination?.institution || '',
          between: row?.examination?.between || '',
          qualification: row?.examination?.qualification || '',
          branch: row?.examination?.branch || '',
          gpa: row?.examination?.gpa || '',
          editable: {
            isEditName: true,
            isEditSurname: true,
            isEditIdCardNo: type !== USER_TYPE.TEMP,
            isEditMobile: true,
            isEditBirthDate: type !== USER_TYPE.TEMP,
          },
        })
        list.push(row.uuid)
      }
    })
    setProspectDetail(selectedRows)
    if (_.isEmpty(list)) {
      deleteFromStorage('prospect')
      deleteFromStorage('prospectDetail')
      dispatch(
        slicesDialog.openDialog({
          iconType: 'error',
          title: 'ไม่สามารถสมัครเข้าอบรม/สอบได้',
          message: ['เนื่องจากสมาชิกที่เลือกไม่สามารถสมัครเข้าอบรม/สอบได้'],
        })
      )
    } else {
      dispatch(slicesDialog.closeDialog())
      if (selectActionType === 'enroll') {
        setProspect(list)
        window.location.href = '/manage-prospect/enrollment'
      } else {
        dispatch(handleSubmitSentMail(list, true))
      }
    }
  }

export const bodySeach = {
  consent: [],
  isRegister: [],
  isAgent: [],
  status: [],
  page: 1,
}

export const fetchDataList =
  (method, table, quickSeach, filterProp) => async (dispatch) => {
    dispatch(startLoading())
    const page = _.get(table, 'table.page', 0)
    const limit = _.get(table, 'table.limit', '20')
    const order = _.get(table, 'table.order', 'asc')
    const sort = _.get(table, 'table.sort', 'fullNameTH')
    const user = JSON.parse(localStorage.getItem('user'))
    const realPage = method != 'fetch' ? 1 : page <= 0 ? 1 : +page
    const name =
      method === 'search' ? quickSeach : _.get(filterProp, 'name', '')
    const isAgent = _.get(filterProp, 'isAgent', [])
    const consent = _.get(filterProp, 'consent', [])
    const isRegister = _.get(filterProp, 'isRegister', [])
    const status = _.get(filterProp, 'status', [])

    let body = {
      unitNumber: _.get(user, 'unitNumber', ''),
      name: quickSeach ? quickSeach : name,
      consent: consent,
      isRegister: isRegister,
      isAgent: isAgent,
      limit: limit,
      status: status,
      order: order.toString().toUpperCase(),
      page: method === 'search' ? 1 : realPage,
      sort: formatData(sort),
    }

    const abbreviation = _.get(user, 'distributionChannel.abbreviation', '')

    const headCellsTable =
      abbreviation === 'CS'
        ? manageProspectCells.filter((item) => item.id !== 'agent')
        : manageProspectCells

    await axios
      .post(prospectFilter, body)
      .then(({ data }) => {
        const rows = data.result
        dispatch(
          setInitialTable({
            rows,
            allCount: data.totalCount,
            headCells: headCellsTable,
            placeholder: 'ค้นหาชื่อ',
            searchKey: 'fullNameTH',
            handleSearch: (text) => dispatch(handleQuickSearch(table, text)),
            onDownload: (selected, sortStr, orderStr) =>
              dispatch(onDownload(selected, sortStr, orderStr)),
            onDelete: (selected) => {
              if (_.get(selected, 'prospectProfile.register', false) === true) {
                if (
                  user?.agentRole === 'Manager' &&
                  _.get(selected, 'disableDelete', false) === true
                ) {
                  dispatch(
                    slicesDialog.openDialog({
                      title: `ไม่สามารถลบผู้มุ่งหวังได้`,
                      titleColor: '#C91432',
                      iconType: 'error',
                      message: [
                        `เนื่องจาก คุณ <b>${selected.firstNameTH} ${selected.lastNameTH}</b>\n`,
                        `มีการลงทะเบียน <b>อบรม/สอบ</b> ไว้`,
                      ],
                      buttonRight: {
                        label: 'รับทราบ',
                        handleClick: () => {
                          dispatch(slicesDialog.closeDialog())
                        },
                        variant: 'outlined',
                      },
                    })
                  )
                } else {
                  dispatch(
                    slicesDialog.openDialog({
                      title: `ยืนยันการลบผู้มุ่งหวัง`,
                      iconType: 'warning',
                      message: [
                        `ท่านกำลังลบ คุณ <b>${selected.firstNameTH} ${selected.lastNameTH}</b> จากการเป็นผู้มุ่งหวัง \n`,
                        '\n',
                        `ขณะนี้คุณ <b>${selected.firstNameTH} ${selected.lastNameTH}</b> มีการลงทะเบียน <b>อบรม/สอบ</b> ไว้\n<b><span style="color:#C91432">หากดำเนินการลบ จะทำให้คุณ ${selected.firstNameTH} ${selected.lastNameTH} ไม่สามารถเข้าระบบ AMS และ <u>ไม่สามารถเข้าอบรมได้</u></span></b>`,
                        '\n',
                        `<b>ต้องการลบผู้มุ่งหวังใช่หรือไม่?</b>`,
                      ],
                      buttonLeft: {
                        label: 'ยกเลิก',
                        handleClick: () => {
                          dispatch(slicesDialog.closeDialog())
                        },
                      },
                      buttonRight: {
                        label: 'ยืนยัน',
                        handleClick: () => {
                          dispatch(onDelete(selected, table, filterProp))
                        },
                      },
                    })
                  )
                }
              } else {
                dispatch(
                  slicesDialog.openDialog({
                    title: 'ลบสมาชิกออกจากทีม',
                    message: ['คุณต้องการลบสมาชิกในทีมใช่หรือไม่?'],
                    buttonLeft: {
                      label: 'ยกเลิก',
                      handleClick: () => {
                        dispatch(slicesDialog.closeDialog())
                      },
                    },
                    buttonRight: {
                      label: 'ยืนยัน',
                      handleClick: () => {
                        dispatch(onDelete(selected, table, filterProp))
                      },
                    },
                  })
                )
              }
            },
            onResendRequest: (selected) =>
              dispatch(
                slicesDialog.openDialog({
                  title: 'ส่งคำขอเข้าร่วมทีมอีกครั้ง',
                  message: ['คุณต้องการส่งคำขอการเป็นสมาชิกในทีมอีกครั้ง?'],
                  buttonLeft: {
                    label: 'ยกเลิก',
                    handleClick: () => {
                      dispatch(slicesDialog.closeDialog())
                    },
                  },
                  buttonRight: {
                    label: 'ยืนยัน',
                    handleClick: () => {
                      dispatch(onResend(selected, table, filterProp))
                    },
                  },
                })
              ),
            onSelect: (row) => dispatch(handleSelect(row)),
            onSelectAll: (events, rows) =>
              dispatch(handleSelectAll(events, rows)),
          })
        )
      })
      .catch(() =>
        dispatch(
          setInitialTable({
            rows: [],
            allCount: 0,
            headCells: headCellsTable,
          })
        )
      )

    dispatch(stopLoading())
  }

export const onFilterClick = (table, filter) => (dispatch) => {
  dispatch(setSearchText(''))
  const accessTo = []
  if (filter.accessToChecked) {
    if (filter.accessTo?.acceptChecked) {
      accessTo.push('CONSENT')
    }
    if (filter.accessTo?.WaintForAcceptChecked) {
      accessTo.push('WAITING')
    }
  }

  const represent = []
  if (filter.representChecked) {
    if (filter.represent?.isRepresentChecked) {
      represent.push('AGENT')
    }
    if (filter.represent?.notRepresentChecked) {
      represent.push('NOT_AGENT')
    }
  }

  const application = []
  if (filter.applicationChecked) {
    if (filter.application?.appliedChecked) {
      application.push(true)
    }
    if (filter.application?.notApplyChecked) {
      application.push(false)
    }
  }

  const status = []
  if (filter.statusChecked) {
    if (filter.status?.inactiveChecked) {
      status.push('INACTIVE')
    }
    if (filter.status?.activeChecked) {
      status.push('ACTIVE')
    }
    if (filter.status?.deletedChecked) {
      status.push('DELETED')
    }
    if (filter.status?.stealChecked) {
      status.push('STEAL')
    }
    if (filter.status?.terminatedChecked) {
      status.push('TERMINATED')
    }
  }

  const filterText = {
    name: filter.nameChecked ? filter.name : '',
    consent: filter.accessToChecked ? accessTo : [],
    isRegister: filter.applicationChecked ? application : [],
    isAgent: filter.representChecked ? represent : [],
    status: filter.statusChecked ? status : [],
  }

  dispatch(setFilterProp(filterText))
  dispatch(setFilterTotal(filter))
  dispatch(resetTablePage())

  dispatch(fetchDataList('filter', table, '', filterText))
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, text))
}

export const handleSelect = (row) => (dispatch) => {
  const list = store.getState().manageProspect.selectedList
  const selectedIndex = list.findIndex((ele) => ele.uuid === row.uuid)
  let newSelected = []
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(list, row)
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(list.slice(1))
  } else if (selectedIndex === list.length - 1) {
    newSelected = newSelected.concat(list.slice(0, -1))
  } else {
    newSelected = newSelected.concat(
      list.slice(0, selectedIndex),
      list.slice(selectedIndex + 1)
    )
  }
  dispatch(setSelectedList(newSelected))
}

export const handleSelectAll = (event, rows) => (dispatch) => {
  if (event?.target?.checked) {
    dispatch(setSelectedList(rows))
    return
  }
  dispatch(setSelectedList([]))
}
