import _ from 'lodash'
import { store } from 'src/App'
import { path } from 'src/constants/path'
import { openErrorDialog } from 'src/redux/slices/alertDialog'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import { getEConfiguration } from 'src/services/eConfiguration'
import { getCourseBatch } from 'src/services/eLearning/enroll'

export const fetchCourseDetail = (uuid) => async (dispatch) => {
  dispatch(getEConfiguration())
  dispatch(startLoading())
  const response = await dispatch(getCourseBatch(uuid))
  dispatch(stopLoading())

  if (!_.isEmpty(response.error)) {
    const error = _.get(
      response,
      'meta.response.data.constraints',
      _.get(response, 'meta.response.data.response.constraints', {})
    )
    dispatch(
      openErrorDialog({
        ...error,
        title: error.title || 'ผิดพลาด',
        message: error.value || 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
        buttonRight: {
          handleClick: () =>
            (window.location.href = `${path.eLearningCourse}/${uuid}`),
        },
      })
    )
  }
}

export const getEnrollState = () => {
  return store.getState().eLearningEnroll
}
