import React from 'react'
import Typography from '@mui/material/Typography'
import HighlightOff from '@mui/icons-material/HighlightOff'
import DialogContent from 'src/modules/ELearning/components/DialogContent'
import { Column, Row } from 'src/components/Layout/Styled'
import { StyledPreview } from './Styled'

const QualityFailDialog = (props) => {
  const { idCardImageKey, isBrightnessFail, isSharpnessFail } = props
  const imgSrc = `${window.__env__.REACT_APP_REKOGNITION_URL}${idCardImageKey}`
  return (
    <DialogContent>
      <Column sx={{ alignItems: 'center', gap: 2, my: '10px' }}>
        <StyledPreview src={imgSrc} />
        <Column sx={{ alignItems: 'start', width: 193 }}>
          {isBrightnessFail && (
            <Row gap={1}>
              <HighlightOff color="error" />
              <Typography variant="body2" color="error.main">
                แสงสว่างของภาพไม่เพียงพอ
              </Typography>
            </Row>
          )}
          {isSharpnessFail && (
            <Row gap={1}>
              <HighlightOff color="error" />
              <Typography variant="body2" color="error.main">
                ความคมชัดของภาพไม่เพียงพอ
              </Typography>
            </Row>
          )}
        </Column>
      </Column>
    </DialogContent>
  )
}

export default QualityFailDialog
