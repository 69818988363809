import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const imageStyle = { margin: '0 auto', width: '100%' }

export const StyledFileRenderWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(10)} 0`,
  img: {
    objectFit: 'contain',
    border: '5px solid',
    borderColor: theme.palette?.text?.gray,
  },
  [theme.breakpoints.down('lg')]: { padding: `0 ${theme.spacing(5)} 0` },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(11)}`,
  },
}))

export const StyledCenteredContent = {
  position: 'absolute',
  top: 200,
  left: '50%',
  transform: 'translate(-50%)',
}

export const StyledZipDisplay = styled('img')(() => ({
  width: 100,
  ...StyledCenteredContent,
}))

export const StyledZipDownloadBtn = styled(Button)(() => ({
  ...StyledCenteredContent,
  top: 335,
}))

export const StyledWrapper = styled(Column)(({ theme }) => ({
  gap: theme.spacing(5),
  paddingBottom: theme.spacing(10),
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: { paddingBottom: theme.spacing(5) },
  [theme.breakpoints.down('sm')]: { padding: 0 },
}))
