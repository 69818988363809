/* eslint-disable no-misleading-character-class */
/* eslint-disable no-useless-escape */
import React from 'react'
import _ from 'lodash'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ContentRemark from '../../../components/AlertDialog/ContentDialogRemark'
import { manageProspectCells, manageProspectExaminationCells } from './model'
import {
  setInitialTable,
  setSearchText,
  resetTablePage,
} from '../../../redux/slices/table'

import {
  setInitialTable as setInitialTable2,
  setSearchText as setSearchText2,
  resetTablePage as resetTablePage2,
} from '../../../redux/slices/table2'
import {
  fetchDataForm,
  setChangeType,
  setChangeInfo,
} from '../../../redux/slices/manageProspect'

import {
  openDialog,
  closeDialog,
  loadingDialog,
  stopLoadingDialog,
  setEnableButton,
  setDisableButton,
} from '../../../redux/slices/alertDialog'
import {
  startLoading,
  startLoading2,
  stopLoading,
  stopLoading2,
} from '../../../redux/slices/loading'

import {
  formManageProspect,
  getManageProspectById,
  uploadImageProspect,
  postProspectRegisterAadTest,
  putProspectCancelRegistration,
  postProspectSentMail,
  postProspectExamination,
  deleteManagerExamination,
  getStaffById,
  uploadImageStaff,
  updateStaff,
} from '../../../services/manageProspect'
import { ContentDialog } from '.'
import { store } from 'src/App'
import { USER_TYPE } from 'src/modules/EExamination/constant'
import { getQualification } from '../../../services/eExamination'
import { profile } from '../../../utils/apiPath'
import axios from 'axios'

export const handleEdit = (history, uuid) => (dispatch) => {
  dispatch(setSearchText(''))
  history.push('/manage-prospect/' + uuid)
}

export const handleChangeType = (props) => (dispatch) => {
  dispatch(setChangeType(props))
}

export const fetchOption = () => async (dispatch) => {
  dispatch(startLoading())
  await dispatch(getQualification())
  dispatch(stopLoading())
}

export const fetchDataById = (props) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const staffType = _.get(user, 'staffProfile.type', '')
  const { uuid, table, searchText, method } = props
  const { page, limit, order, sort } = table
  const realPage = page <= 0 ? 1 : +page
  const body = {
    prospectUuid: uuid,
    courseName: searchText,
    limit: limit,
    order: order.toString().toUpperCase(),
    page: realPage,
    sort: sort === 'updatedAt' ? 'id' : sort,
  }

  dispatch(startLoading())

  const result = await Promise.all([
    dispatch(
      staffType !== 'NON_ACADEMY'
        ? getManageProspectById(uuid)
        : getStaffById(uuid)
    ),
    dispatch(postProspectRegisterAadTest(body)),
    dispatch(getQualification()),
  ])

  if (method == 'fetch') dispatch(setDataForm(result[0]))

  dispatch(setDataHistory({ result: result[1], props }))
  dispatch(stopLoading())
}

export const fetchDataById2 = (props) => async (dispatch) => {
  const { uuid, table, searchText } = props
  const { page, limit, order, sort } = table
  const realPage = page <= 0 ? 1 : +page
  const body = {
    prospectUuid: uuid,
    search: searchText,
    limit: limit,
    order: order.toString().toUpperCase(),
    page: realPage,
    sort: sort,
  }

  dispatch(startLoading2())

  const result = await Promise.all([dispatch(postProspectExamination(body))])

  dispatch(setDataHistory2({ result: result[0], props }))
  dispatch(stopLoading2())
}

export const checkIdCard = (id) => {
  if (!isNumeric(id)) return false
  if (id.substring(0, 1) == 0) return false
  if (id.length != 13) return false
  let sum = 0
  for (let i = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i)
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false
  return true
}

export const isNumeric = (input) => {
  var RE = /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+))$/
  var RE2 = /^((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?)$/
  return RE.test(input) || RE2.test(input)
}

export const handleSteal = (value) => (dispatch) => {
  dispatch(openDialog(dispatch(findObjDialog('steal', value))))
}

export const handleSubmit = (value) => async (dispatch) => {
  dispatch(
    openDialog({
      title: 'บันทึกข้อมูล',
      message: ['คุณต้องการบันทึกข้อมูลนี้?'],
      buttonLeft: {
        label: 'ยกเลิก',
      },
      buttonRight: {
        handleClick: () => dispatch(actionSubmit(value)),
      },
    })
  )
}

export const actionSubmit = (value) => async (dispatch) => {
  dispatch(loadingDialog())
  dispatch(setEnableButton())
  const user = JSON.parse(localStorage.getItem('user'))
  const staffType = _.get(user, 'staffProfile.type', '')

  const caseType = value.caseType
  const uuid = _.get(value, 'information.uuid', '')

  let body = {
    image: _.get(value, 'information.image', ''),
    prefixTH: _.get(value, 'information.prefixTH', ''),
    firstNameTH: _.get(value, 'information.firstNameTH', ''),
    lastNameTH: _.get(value, 'information.lastNameTH', ''),
    prefix: _.get(value, 'information.prefix', ''),
    firstName: _.get(value, 'information.firstName', ''),
    lastName: _.get(value, 'information.lastName', ''),
    mobileNo: _.get(value, 'information.mobileNo', '')?.replace(/-/g, ''),
    email: _.get(value, 'information.email', ''),
    division: _.get(value, 'information.division', ''),
    qualification: _.get(value, 'information.qualification', ''),
    dateOfBirth: _.get(value, 'information.dateOfBirth', null),
    idCardNo: _.get(value, 'information.idCardNo', ''),
    branch: _.get(value, 'information.branch', ''),
    gpa: _.get(value, 'information.gpa', ''),
    between: _.get(value, 'information.between', ''),
    institution: _.get(value, 'information.institution', ''),
    educational: filterDataEmpty(_.get(value, 'information.educational', [])),
    isSteal: _.get(value, 'isSteal', false),
  }

  if (caseType == 'edit') {
    body = { ...body, uuid }
  } else {
    body = {
      ...body,
      unitNumber: _.get(value, 'unitNumber', ''),
    }
  }

  const result = await dispatch(
    staffType !== 'NON_ACADEMY'
      ? formManageProspect({ body, caseType })
      : updateStaff({ body, caseType })
  )
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  const isSteal = _.get(result, 'payload.steal.isSteal', false)
  dispatch(stopLoadingDialog())
  if (requestStatus == 'fulfilled') {
    axios
      .get(profile)
      .then((res) => {
        const userData = _.get(res, 'data', null)
        localStorage.setItem('user', JSON.stringify(userData))
      })
      .catch((err) => {
        const errStatus = _.get(err, 'response.status', 500)
        window.localStorage.setItem('log_error', errStatus)
        if (errStatus !== 0) {
          window.location.href = '/login'
        }
      })

    dispatch(setDisableButton())
    const status = _.get(result, 'payload.status', 'ERROR')
    const listError = _.get(result, 'payload.error', [])
    const uuidTemp = _.get(result, 'payload.temp.uuid', '')

    if (isSteal) {
      dispatch(
        handleSteal({
          ...value,
          isSteal: true,
          steal: _.get(result, 'payload.steal', {}),
        })
      )
    } else if (status === 'ERROR' && listError.length) {
      if (_.includes(listError, 'emailDms')) {
        dispatch(
          openDialog(
            dispatch(findObjDialog('emailDuplicate', listError, value))
          )
        )
      } else if (_.includes(listError, 'idCardNoDms')) {
        dispatch(
          openDialog(
            dispatch(findObjDialog('idCardDuplicate', listError, value))
          )
        )
      } else if (_.includes(listError, 'dmsFullformInProcess')) {
        dispatch(
          openDialog(
            dispatch(findObjDialog('dmsFullformInProcess', listError, value))
          )
        )
      } else {
        dispatch(
          openDialog(
            dispatch(findObjDialog('singleDuplicate', listError, value))
          )
        )
      }
    } else {
      if (staffType !== 'NON_ACADEMY') {
        await dispatch(
          postProspectSentMail({
            tempUuid: [uuidTemp],
          })
        )
      }
      dispatch(
        openDialog(
          dispatch(
            findObjDialog(
              caseType == 'edit' ? 'editSuccess' : 'createSuccess',
              uuidTemp,
              _.get(result, 'payload.temp', {})
            )
          )
        )
      )
    }
  }
}

export const findObjDialog = (caseType, data, value) => (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const staffType = _.get(user, 'staffProfile.type', '')
  const abbreviation = _.get(user, 'distributionChannel.abbreviation', 'AGC')

  let titleDaialog = 'สร้างตัวแทนรอรหัสเรียบร้อยแล้ว'
  let messageDaialog = [
    'ระบบได้ส่งอีเมลให้กับตัวแทนรอรหัสของท่าน',
    'เพื่อยินยอมการใช้ข้อมูลส่วนบุคคลสำหรับการฝึกอบรม',
  ]
  if (abbreviation === 'CS') {
    titleDaialog = 'สร้างสมาชิกในทีมสำเร็จแล้ว'
    messageDaialog = [
      'ระบบได้ส่งอีเมลให้กับสมาชิกของท่าน',
      'เพื่อยินยอมการใช้ข้อมูลส่วนบุคคลสำหรับการฝึกอบรม',
    ]
  }

  if (caseType === 'singleDuplicate') {
    if (value.caseType !== 'edit') {
      messageDaialog = 'ไม่สามารถสร้างสมาชิกใหม่ได้'
    } else {
      messageDaialog = 'ไม่สามารถแก้ไขข้อมูลได้'
    }
  }
  switch (caseType) {
    case 'editSuccess':
      return {
        isCloseDialog: false,
        iconType: 'success',
        title: 'แก้ไขข้อมูลส่วนตัวสำเร็จ',
        message: ['บันทึกเรียบร้อย'],
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            user.userType === 'PROSPECT' || staffType === 'NON_ACADEMY'
              ? (window.location.href = '/my-profile')
              : (window.location.href = '/manage-prospect')
          },
        },
      }
    case 'createSuccess':
      return {
        iconType: 'success',
        isCloseDialog: false,
        title: titleDaialog,
        message: messageDaialog,
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            window.location.href = '/manage-prospect'
          },
        },
      }
    case 'singleDuplicate':
      return {
        isCloseDialog: false,
        iconType: 'error',
        title: 'มีข้อมูลในระบบแล้ว',
        message: [messageDaialog],
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            mapErrorDuplicate(data, value.setErrors)
            dispatch(closeDialog())
          },
        },
      }
    case 'emailDuplicate':
      return {
        isCloseDialog: false,
        iconType: 'error',
        title: 'ไม่สามารถใช้อีเมลนี้ได้',
        message: [
          'ขออภัยคุณไม่สามารถใช้อีเมลนี้ในการเพิ่มสมาชิกในทีมได้ \nกรุณาเปลี่ยนอีเมล หรือ โทร 1159 เพื่อติดต่อเจ้าหน้าที่',
        ],
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            mapErrorDuplicate(data, value.setErrors)
            dispatch(closeDialog())
          },
        },
      }
    case 'idCardDuplicate':
      return {
        isCloseDialog: false,
        iconType: 'error',
        title: 'ไม่สามารถใช้หมายเลขบัตรประจำตัวประชาชน',
        message: [
          'ขออภัยคุณไม่สามารถใช้หมายเลขบัตรประจำตัวประชาชนนี้\nในการอัปเดตข้อมูลได้ กรุณาเปลี่ยนหมายเลขบัตรประจำตัวประชาชน \nหรือ โทร 1159 เพื่อติดต่อเจ้าหน้าที่',
        ],
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            mapErrorDuplicate(data, value.setErrors)
            dispatch(closeDialog())
          },
        },
      }
    case 'dmsFullformInProcess':
      return {
        isCloseDialog: false,
        iconType: 'error',
        title: 'ไม่สามารถแก้ไขข้อมูลได้',
        message: [
          'ขออภัยคุณไม่สามารถแก้ไขข้อมูลได้ เนื่องจากกำลังอยู่ในขั้นตอน\nการตรวจสอบข้อมูลฟอร์มแบบเต็ม \nกรุณาแก้ไขข้อมูล หรือ โทร 1159 เพื่อติดต่อเจ้าหน้าที่',
        ],
        buttonRight: {
          label: 'ตกลง',
          handleClick: () => {
            mapErrorDuplicate(data, value.setErrors)
            dispatch(closeDialog())
          },
        },
      }
    case 'uploadProfileErrorType':
      return {
        iconType: 'error',
        title: 'นามสกุลไฟล์ไม่ถูกต้อง',
        message: ['ไม่สามารถ Upload ได้', 'เนื่องจากนามสกุลไฟล์ไม่ถูกต้อง'],
        buttonRight: {
          label: 'ตกลง',
        },
      }
    case 'uploadProfileErrorSize':
      return {
        iconType: 'error',
        title: 'ขนาดไฟล์ใหญ่เกินไป',
        message: ['ขนาดไฟล์มีขนาดใหญ่เกิน 2 MB', 'กรุณาลองใหม่อีกครั้ง'],
        buttonRight: {
          label: 'ตกลง',
        },
      }
    case 'steal':
      return {
        iconType: 'warning',
        isCloseDialog: false,
        title: 'ยืนยันการย้ายผู้ชักชวน',
        customWidth: '580px ',
        message: [
          `ขณะนี้คุณ${_.get(data, 'steal.name', '-')} เป็นสมาชิกของคุณ${_.get(
            data,
            'steal.currentManagerName',
            '-'
          )}`,
          `หน่วย ${_.get(data, 'steal.currentUnitName', '-')}`,
          'ต้องการเพิ่มเป็นสมาชิกของคุณหรือไม่?',
        ],
        buttonLeft: {
          label: 'ยกเลิก',
        },
        buttonRight: {
          label: 'ยืนยัน',
          endIcon: <ArrowForward />,
          handleClick: () => {
            dispatch(openDialog(dispatch(actionSubmit(data))))
          },
        },
      }
    case 'selectAction':
      return {
        isCloseDialog: false,
        iconType: 'course',
        title: 'สมัครเข้าอบรม / สมัครสอบ',
        message: ['ท่านต้องการสมัครอบรม / สมัครสอบให้กับตัวแทนรอรหัสหรือไม่?'],
        content: <ContentDialog />,
        buttonLeft: {
          handleClick: () => {
            dispatch(handleSubmitSentMail(data))
          },
        },
        buttonRight: {
          handleClick: () => {
            dispatch(handleClickEnroll(data, value))
          },
        },
      }
  }
}

export const handleClickEnroll =
  (prospectUuid, prospectDetail) => (dispatch) => {
    const state = store.getState()
    const selectActionType = _.get(
      state.manageProspect,
      'selectActionType',
      'enroll'
    )
    const user = JSON.parse(localStorage.getItem('user'))
    const userImageUrl = prospectDetail?.image
      ? `${window.__env__.REACT_APP_FILE_URL}${prospectDetail?.image}`
      : '/logo/staff_profile_small.png'
    const type = prospectDetail?.type || 'TEMP'
    const detail = [
      {
        uuid: prospectDetail?.uuid || '',
        type: type,
        unitName: user?.agentProfile?.agentUnitName || '',
        unitNumber: prospectDetail?.unitNumber || '',
        image: userImageUrl,
        firstNameTH: prospectDetail?.firstNameTH || '',
        lastNameTH: prospectDetail?.lastNameTH || '',
        prefix: prospectDetail?.prefixTH || '',
        mobileNo: prospectDetail?.mobileNo?.replace(/-/g, '') || '',
        email: prospectDetail?.email || '',
        idCardNo: prospectDetail?.idCardNo || '',
        isEdit: true,
        birthDate: prospectDetail?.dateOfBirth || null,
        institution: prospectDetail?.institution || '',
        between: prospectDetail?.between || '',
        qualification: prospectDetail?.qualification || '',
        branch: prospectDetail?.branch || '',
        gpa: prospectDetail?.gpa || '',
        editable: {
          isEditName: true,
          isEditSurname: true,
          isEditIdCardNo: type !== USER_TYPE.TEMP,
          isEditMobile: true,
          isEditBirthDate: type !== USER_TYPE.TEMP,
        },
      },
    ]
    localStorage.setItem('prospectDetail', JSON.stringify(detail))
    if (selectActionType === 'enroll') {
      localStorage.setItem('prospect', prospectUuid)
      window.location.href = '/manage-prospect/enrollment'
    } else {
      dispatch(handleSubmitSentMail(prospectUuid, true))
    }
  }

export const handleSubmitSentMail =
  (data, eexam = false) =>
  async (dispatch) => {
    let listUuid = []
    if (_.isEmpty(data)) window.location.href = '/manage-prospect'
    dispatch(loadingDialog())
    if (_.isArray(data)) {
      listUuid = data
    } else {
      if (data.substring(1, 0) === '[') {
        listUuid = JSON.parse(data)
      } else {
        listUuid = Array(data)
      }
    }
    const result = await dispatch(
      postProspectSentMail({
        tempUuid: listUuid,
      })
    )
    localStorage.removeItem('prospect')
    const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
    if (requestStatus == 'fulfilled' && !eexam) {
      window.location.href = '/manage-prospect'
    } else if (requestStatus == 'fulfilled' && eexam) {
      window.location.href = '/e-examination'
    } else {
      dispatch(stopLoadingDialog())
    }
  }

export const handleUpload = (event) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const staffType = _.get(user, 'staffProfile.type', '')
  const body = new FormData()

  let elementId = document.getElementById('btn-upload-profile')
  if (event.target.files.length === 0) {
    elementId.value = null
    return
  }
  body.append('file', event.target.files[0])
  const getNameImage = _.get(event, 'target.files[0].name', undefined)
  if (!getNameImage) {
    elementId.value = null
    return
  }

  const sizeFile = _.get(event, 'target.files[0].size', 0)
  const getExtensionImage = getNameImage.split('.').pop()

  if (!checkTypeFile(getExtensionImage.toString().toLowerCase())) {
    elementId.value = null
    dispatch(openDialog(dispatch(findObjDialog('uploadProfileErrorType'))))
    return
  }

  if (sizeFile > 2000000) {
    elementId.value = null
    dispatch(openDialog(dispatch(findObjDialog('uploadProfileErrorSize'))))
    return
  }

  const result = await dispatch(
    staffType !== 'NON_ACADEMY'
      ? uploadImageProspect(body)
      : uploadImageStaff(body)
  )
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  if (requestStatus == 'fulfilled') {
    dispatch(
      setChangeInfo({ key: 'image', value: _.get(result, 'payload.key', '') })
    )
  }
}

export const checkTypeFile = (type) => {
  return type === 'jpg' || type === 'jpeg' || type === 'png'
}

export const mapErrorDuplicate = (listError, setErrors) => {
  let resErrors = {}
  listError.filter((item) => {
    resErrors = {
      ...resErrors,
      [item]: 'กรุณากรอกข้อมูลที่ไม่ซ้ำกับสมาชิกท่านอื่น',
    }
  })
  return setErrors({ information: resErrors })
}

export const filterDataEmpty = (list) => {
  if (!list.length) return []
  let result = []
  list.filter((item) => {
    if (!checkEmpty(item)) result.push(item)
  })
  return result
}

export const checkEmpty = (item) => {
  let check = true
  for (let key in item) {
    if (!_.isEmpty(item[key])) check = false
  }
  return check
}

export const handleQuickSearch = (props) => (dispatch) => {
  dispatch(setSearchText(props.searchText))
  dispatch(resetTablePage())
  dispatch(fetchDataById(props))
}

export const handleQuickSearch2 = (props) => (dispatch) => {
  dispatch(setSearchText2(props.searchText))
  dispatch(resetTablePage2())
  dispatch(fetchDataById2(props))
}

export const onDeleted = (props) => async (dispatch) => {
  const result = await dispatch(putProspectCancelRegistration(props.bodyDelete))
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  const status = _.get(result, 'payload.status', 'ERROR')

  if (requestStatus == 'fulfilled') {
    dispatch(
      openDialog({
        iconType: status === 'ERROR' ? 'error' : 'success',
        isCloseDialog: false,
        title: status === 'ERROR' ? 'ไม่สามารถยกเลิกได้' : 'ยกเลิกสำเร็จแล้ว',
        message: [
          status === 'ERROR'
            ? 'เนื่องจากคลาสเรียนนี้ได้เริ่มต้นการอบรมแล้ว'
            : 'บันทึกเรียบร้อย',
        ],
        buttonRight: {
          handleClick: () => {
            dispatch(closeDialog())
            dispatch(dispatch(fetchDataById(props)))
          },
        },
      })
    )
  }
}

export const onDeleted2 = (props) => async (dispatch) => {
  const result = await dispatch(deleteManagerExamination(props.bodyDelete))
  const requestStatus = _.get(result, 'meta.requestStatus', 'rejected')
  const status = _.get(result, 'payload.status', null)

  if (requestStatus === 'fulfilled') {
    dispatch(
      openDialog({
        iconType: status === 'ERROR' ? 'error' : 'success',
        isCloseDialog: false,
        title: status === 'ERROR' ? 'ไม่สามารถยกเลิกได้' : 'ยกเลิกสำเร็จแล้ว',
        message: [
          status === 'ERROR'
            ? 'เนื่องจากตารางสอบได้เริ่มต้นขึ้นแล้ว'
            : 'บันทึกเรียบร้อย',
        ],
        buttonRight: {
          handleClick: () => {
            dispatch(closeDialog())
            dispatch(dispatch(fetchDataById2(props)))
          },
        },
      })
    )
  }
}

export const setDataForm = (result) => (dispatch) => {
  const defaultEducation = [
    {
      institution: '',
      between: '',
      education: '',
      branch: '',
      GPA: '',
    },
  ]
  const res = _.get(result, 'payload', {})
  const educational = _.get(res, 'educational', [])
  const fullName =
    _.get(res, 'firstNameTH', '') + ' ' + _.get(res, 'lastNameTH', '')

  dispatch(
    fetchDataForm({
      information: {
        ...res,
        fullName,
        educational: !educational.length ? defaultEducation : educational,
      },
    })
  )
}

export const setDataHistory =
  ({ result, props }) =>
  (dispatch) => {
    dispatch(
      setInitialTable({
        isCheckBox: false,
        rows: _.get(result, 'payload.result', []),
        allCount: _.get(result, 'payload.totalCount', 0),
        headCells: manageProspectCells,
        placeholder: 'ค้นหาจากชื่อหลักสูตร',
        searchKey: 'courseName',
        isHideSearch: true,
        handleSearch: (searchText) =>
          dispatch(
            handleQuickSearch({ ...props, searchText, method: 'search' })
          ),
        onDelete: (uuidDelete, name) =>
          dispatch(
            openDialog({
              title: 'ต้องการยกเลิกการสมัคร',
              message: [`คุณต้องการยกเลิกการสมัคร ${name} ใช่หรือไม่?`],
              isHiddenFooter: true,
              content: (
                <ContentRemark
                  labelInput="กรุณาใส่เหตุผลเพื่อแจ้งให้ผู้เรียนทราบ"
                  submitDialog={(remark) =>
                    dispatch(
                      onDeleted({
                        ...props,
                        uuidDelete,
                        method: 'delete',
                        bodyDelete: {
                          classLearnerUuid: uuidDelete,
                          remark,
                        },
                      })
                    )
                  }
                />
              ),
            })
          ),
      })
    )
  }

export const setDataHistory2 =
  ({ result, props }) =>
  (dispatch) => {
    dispatch(
      setInitialTable2({
        isCheckBox: false,
        rows: _.get(result, 'payload.result', []),
        allCount: _.get(result, 'payload.totalCount', 0),
        headCells: manageProspectExaminationCells,
        placeholder: 'ค้นหา',
        searchKey: 'search',
        isHideSearch: true,
        handleSearch: (searchText) =>
          dispatch(
            handleQuickSearch2({ ...props, searchText, method: 'search' })
          ),
        onDelete: (uuid, name, type) =>
          dispatch(
            openDialog({
              title: 'ต้องการยกเลิกการสมัคร',
              message: [`คุณต้องการยกเลิกการสมัคร ${name} ใช่หรือไม่?`],
              buttonLeft: {
                label: 'ไม่',
                handleClick: () => dispatch(closeDialog()),
              },
              buttonRight: {
                label: 'ใช่',
                handleClick: () =>
                  dispatch(
                    onDeleted2({
                      ...props,
                      method: 'delete',
                      bodyDelete: {
                        uuid: uuid,
                        userType: type,
                      },
                    })
                  ),
              },
            })
          ),
      })
    )
  }
