import React from 'react'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Close from '@mui/icons-material/Close'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { Column } from 'src/components/Layout/Styled'
import FaceLiveness from 'src/components/FaceLiveness'
import CameraReadyBox from './components/CameraReadyBox'
import CameraFrame from './components/CameraFrame'

const CameraDialog = ({
  field = 'faceImage',
  isOpen,
  isShowTooltip,
  image,
  onCapture,
  onOpenCamera,
  onError,
  onClose,
  children,
  isLiveness,
}) => {
  const isNotSm = isMediaWidthMoreThan('sm')
  const enter = isNotSm ? '' : <br />
  const { innerHeight } = window
  return (
    <Column id="camera-dialog" p={2} gap="18px" alignItems="center">
      {onClose && (
        <IconButton
          color="primary"
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      )}
      {children || (
        <Column gap={0.5} alignItems="center">
          <Typography variant={isNotSm ? 'h5' : 'h6'}>
            ถ่ายรูปหน้าตรง
          </Typography>
          <Typography
            variant={isNotSm ? 'h6' : 'body2b'}
            color="primary"
            textAlign="center"
            fontWeight={400}
          >
            {isOpen ? (
              innerHeight > 667 && (
                <>
                  กรุณาขยับหน้าของท่านให้พอดีกับกรอบ{enter}
                  จนกว่าระบบจะเริ่มประมวลผล
                </>
              )
            ) : (
              <>
                กรุณาเปิดกล้องแล้วถ่ายรูปในที่ที่มีแสงสว่างมากเพียงพอ
                {enter}และถ่ายไม่ติดใบหน้าผู้อื่น
              </>
            )}
          </Typography>
        </Column>
      )}
      {isOpen ? (
        isLiveness ? (
          <FaceLiveness onCapture={onCapture} onError={onError} />
        ) : (
          <CameraFrame
            field={field}
            image={image}
            isShowTooltip={isShowTooltip}
            onCapture={onCapture}
            onError={onError}
          />
        )
      ) : (
        <CameraReadyBox field={field} onOpenCamera={onOpenCamera} />
      )}
    </Column>
  )
}

export default CameraDialog
