import _ from 'lodash'
import screenfull from 'screenfull'
import { store } from 'src/App'
import {
  setFullScreen,
  setVideoState,
  startLoading,
  stopLoading,
} from 'src/redux/slices/eLearning/learning'
import { getLearningState, handleUpdateLogPerLesson } from '../../../../events'

export const formatTime = (time) => {
  if (isNaN(time)) return '0:00'

  const date = new Date(time.toFixed() * 1000)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const seconds = date.getUTCSeconds().toString().padStart(2, '0')
  if (hours) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`
  } else return `${minutes}:${seconds}`
}

export const handlePlayPause = (isPlay) => (dispatch) => {
  const { selectedLesson } = getLearningState()
  const videoState = _.get(selectedLesson, 'videoState', {})
  console.log('video state before click ->', videoState)
  if (!videoState.seeking || isPlay) {
    const playing = isPlay ? isPlay : !videoState.playing
    dispatch(setVideoState({ playing, isFinished: false }))
  }
}

export const handleSeekMouseDown = () => (dispatch) => {
  dispatch(setVideoState({ seeking: true }))
}

export const handleSeeking = (value) => (dispatch) => {
  const { isSkipAllowed, selectedLesson } = getLearningState()
  const playedPercent = _.get(selectedLesson, 'playedPercent', 0) / 100

  if (!isSkipAllowed && value > playedPercent) {
    dispatch(setVideoState({ seeking: false }))
    return
  }

  const newPlayed = parseFloat(value) / 100
  dispatch(setVideoState({ played: newPlayed }))
}

export const handleSeekEnd = (value, videoPlayerRef) => (dispatch) => {
  const { selectedLesson, isSkipAllowed } = getLearningState()
  const playedPercent = _.get(selectedLesson, 'playedPercent', 0)

  if (!isSkipAllowed && value > playedPercent) return

  const duration = _.get(selectedLesson, 'duration', 0) || 0
  const playedSeconds = _.floor((value / 100) * duration)
  const payload = { playedSeconds, seeking: false, isFinished: false }
  videoPlayerRef.current.seekTo(playedSeconds)
  dispatch(setVideoState(payload))
  dispatch(handleUpdateLogPerLesson())
}

export const handleFastForward = (videoPlayerRef, addSec) => () => {
  const { selectedLesson } = getLearningState()
  const videoState = _.get(selectedLesson, 'videoState', {})
  const current = _.get(videoState, 'playedSeconds', 0)
  const duration = _.get(selectedLesson, 'duration', 0) || 0
  const second = current + addSec
  const destinationSec = second > duration ? duration : second
  videoPlayerRef?.current?.seekTo(destinationSec)
}

export const handleFastRewind = (videoPlayerRef, subSec) => () => {
  const { selectedLesson } = getLearningState()
  const videoState = _.get(selectedLesson, 'videoState', {})
  const current = _.get(videoState, 'playedSeconds', 0)
  const second = current - subSec
  const destinationSec = second < 0 ? 0 : second
  videoPlayerRef?.current?.seekTo(destinationSec)
}

export const handleVolumeChange = (value) => (dispatch) => {
  const newVolume = parseFloat(value) / 100
  // volume === 0 then muted
  dispatch(setVideoState({ volume: newVolume, muted: Number(newVolume) === 0 }))
}

export const handleMute = (videoState) => (dispatch) => {
  dispatch(setVideoState({ muted: !videoState.muted }))
}

export const handleFullscreen = (wrapperRef) => (dispatch) => {
  const elem = wrapperRef.current
  if (!elem) return

  dispatch(setFullScreen())
  if (screenfull) screenfull?.toggle(elem)
}

export const handleHoverOnVideo = (controlRef) => {
  if (!controlRef.current) return

  let timeout = 0

  const displayOpa = () => {
    if (!controlRef.current || !controlRef.current.style) return
    controlRef.current.style.opacity = 1
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (!controlRef.current || !controlRef.current.style) return
      controlRef.current.style.opacity = 0
    }, 1000)
  }

  controlRef.current.addEventListener('mousemove', displayOpa)
  return () => {
    controlRef.current.removeEventListener('mousemove', displayOpa)
  }
}

export const handleKeyDown = (videoPlayerRef) => (dispatch) => {
  const { selectedLesson, isSkipAllowed } = getLearningState()
  const videoState = _.get(selectedLesson, 'videoState', {})
  const volume = _.get(videoState, 'volume', 50)
  const duration = _.get(selectedLesson, 'duration', 0) || 0

  if (_.isNil(duration)) return

  const onKeyDown = (e) => {
    const keySpace = 32
    const keyLeft = 37
    const keyUp = 38
    const keyRight = 39
    const keyDown = 40
    if (
      (e.keyCode === keySpace ||
        e.keyCode === keyUp ||
        e.keyCode === keyDown) &&
      e.target === document.body
    ) {
      e.preventDefault()
    }

    if (e.keyCode === keyLeft) {
      dispatch(handleFastRewind(videoPlayerRef, 5))
    }
    if (isSkipAllowed && e.keyCode === keyRight) {
      dispatch(handleFastForward(videoPlayerRef, 5))
    }

    if (e.keyCode === keyUp) {
      const finalVol = volume * 100 + 5
      dispatch(handleVolumeChange(finalVol > 100 ? 100 : finalVol))
    }
    if (e.keyCode === keyDown) {
      const finalVol = volume * 100 - 5
      dispatch(handleVolumeChange(finalVol < 0 ? 0 : finalVol))
    }
  }

  document.addEventListener('keydown', onKeyDown)
  return () => {
    document.removeEventListener('keydown', onKeyDown)
  }
}

export const handleReload = () => {
  store.dispatch(startLoading())
  store.dispatch(setVideoState({ playing: false }))
  setTimeout(() => {
    store.dispatch(stopLoading())
  }, 500)
}
