import _ from 'lodash'
import {
  setProfileData,
  setCloseDialog,
  setLoadingDialog,
  setOpenDialog,
  setOpenErrorDialog,
  setOpenSuccessDialog,
  setStopLoadingDialog,
} from '../../redux/slices/profile'
import { closeDialog } from '../../redux/slices/alertDialog'
import { postUpdateProfile } from '../../services/profile'
import { startLoading, stopLoading } from '../../redux/slices/loading'

import { getFullFormStatus } from '../../services/dms'

const regex = /^[0-9]+$/

export const handleInputFormat = (e) => {
  const key = e.nativeEvent.data

  if (_.isNil(key)) return e.target.value

  if (!regex.test(key)) e.target.value = e.target.value.slice(0, -1)
  if (!regex.test(e.target.value)) e.target.value = ''
  e.target.value = e.target.value.toString().slice(0, 13)
  return e.target.value
}

export const handlePasteFormat = (e) => {
  e.preventDefault()
  return false
}

export const handleSubmit = (props) => (dispatch) => {
  dispatch(
    setOpenDialog({
      title: 'บันทึกข้อมูล',
      message: ['คุณต้องการบันทึกข้อมูลนี้?'],
      buttonLeft: {
        label: 'ยกเลิก',
      },
      buttonRight: {
        handleClick: () => dispatch(handleConfirm(props)),
      },
    })
  )
}

export const handleConfirm = (props) => async (dispatch) => {
  const { values, user, setUser, onClose } = props
  dispatch(setLoadingDialog())
  const body = { ...values, uuid: user.uuid }
  const res = await dispatch(postUpdateProfile(body))
  const payload = _.get(res, 'payload', null)
  const isMobile = /Mobi|Android/i.test(navigator.userAgent)
  dispatch(setStopLoadingDialog())
  if (payload.data) {
    dispatch(
      setOpenSuccessDialog({
        title: 'อัปเดตข้อมูลสำเร็จ',
        message: '',
        buttonLabel: 'เรียนต่อ',
        handleClick: () => {
          const updatedData = _.get(payload.data, 'updatedData', user)
          setUser(updatedData)
          onClose()
          dispatch(setCloseDialog())
        },
      })
    )
  } else {
    const constraints = _.get(res?.meta?.response, 'data.constraints', {})
    if (constraints) {
      dispatch(
        setOpenErrorDialog({
          title: _.get(constraints, 'title', ''),
          message: [_.get(constraints, 'value', '')],
          buttonRight: {
            label: isMobile ? 'โทรหาเรา' : 'รับทราบ',
            handleClick: () => {
              if (isMobile) {
                window.open = 'tel:1159' // ใช้โทรออก
                dispatch(setCloseDialog())
                dispatch(closeDialog())
              } else {
                dispatch(setCloseDialog())
                dispatch(closeDialog())
              }
            },
          },
        })
      )
    } else {
      dispatch(
        setOpenErrorDialog({
          title: 'อัปเดตข้อมูลไม่สำเร็จ',
          message: ['โปรดลองใหม่อีกครั้ง'],
        })
      )
    }
  }
}

export const fetchDataById =
  ({ uuid }) =>
  async (dispatch) => {
    dispatch(startLoading())
    try {
      const result = await dispatch(getFullFormStatus(uuid))

      if (result.payload?.result) {
        dispatch(setProfileData(result.payload?.result)) // อัพเดต profile data ใน store
      }
      dispatch(stopLoading())
    } catch (error) {
      console.error('Error while fetching data:', error)
    } finally {
      dispatch(stopLoading())
    }
  }
