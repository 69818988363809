import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from '@mui/system/styled'
import { Column } from 'src/components/Layout/Styled'

export const StyledCaptureButton = styled((props) => (
  <Button {...props} variant="contained" />
))(() => ({ width: 82, height: 82, borderRadius: '100%' }))

export const StyledCameraFrame = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.down('sm')]: { width: 300, height: 200 },
}))

export const StyledCameraReadyBox = styled(Column)(({ theme }) => ({
  height: 369,
  background: theme.palette?.grey?.grey700,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: { height: 229 },
}))

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  width: 80,
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
    textAlign: 'center',
    backgroundColor: theme.palette?.blue?.azure,
    '& .MuiTooltip-arrow': { color: theme.palette?.blue?.azure },
  },
}))
