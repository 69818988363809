import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Column, Row } from 'src/components/Layout/Styled'
import DialogContent from 'src/modules/ELearning/components/DialogContent'
import { StyledButton } from 'src/modules/ELearning/components/DialogContent/Styled'
import { handleCountdown } from '../../DisallowDialog/events'
import { handleOpenCameraDialog } from '../events'
import { handleChangeImage, handleFinalSubmit } from './events'

const FinalErrorContent = ({ data }) => {
  const dispatch = useDispatch()
  const { mins, secs } = useSelector((state) => ({
    mins: state.eLearningEnroll.time.mins,
    secs: state.eLearningEnroll.time.secs,
  }))
  const isDoneCountdown = mins === 0 && secs === 0
  const faceImageKey = _.get(data, 'faceImageKey', '')
  const imgSrc = `${window.__env__.REACT_APP_REKOGNITION_URL}${faceImageKey}`
  const [isDisable, setDisable] = useState(false)

  useEffect(() => {
    let timer = null
    timer = setInterval(() => {
      dispatch(handleCountdown(timer))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  return (
    <DialogContent>
      <Column sx={{ gap: '18px', alignItems: 'center' }}>
        <img
          src={imgSrc}
          style={{ width: 216, height: 160, objectFit: 'cover' }}
          onError={() => setDisable(true)}
        />
        <Row sx={{ gap: '18px', justifyContent: 'center' }}>
          <StyledButton
            disabled={!isDoneCountdown}
            variant={isDoneCountdown ? 'outlined' : 'contained'}
            sx={{ px: 0 }}
            onClick={() => {
              handleOpenCameraDialog()
              handleChangeImage()
            }}
          >
            ถ่ายรูปใหม่{!isDoneCountdown && ` (${secs} วินาที)`}
          </StyledButton>
          <StyledButton
            variant="contained"
            disabled={isDisable}
            onClick={() => handleFinalSubmit(data)}
          >
            ยืนยันส่งรูป
          </StyledButton>
        </Row>
      </Column>
    </DialogContent>
  )
}

export default FinalErrorContent
