import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { setDisableSubmit } from 'src/redux/slices/eLearning/enroll'
import { StyledBox } from './Styled'
import { handleOpenDialog } from './events'

const Consent = () => {
  const isDisableSubmit = useSelector(
    (state) => state.eLearningEnroll.isDisableSubmit,
    shallowEqual
  )
  const dispatch = useDispatch()
  return (
    <StyledBox>
      <Checkbox
        onChange={(e) => dispatch(setDisableSubmit(e.target.checked))}
        name="consent"
        checked={!isDisableSubmit}
      />
      <Typography>
        ยอมรับ{' '}
        <Link
          component="span"
          onClick={() => dispatch(handleOpenDialog('consentBodyText'))}
        >
          นโยบายคุ้มครองข้อมูลส่วนบุคคล
        </Link>
        สำหรับการอบรมเก็บชั่วโมงออนไลน์หลักสูตรระดับสูงและ
        <Link
          component="span"
          onClick={() => dispatch(handleOpenDialog('TERMS_AND_CONDITIONS_'))}
        >
          ข้อตกลงและเงื่อนไขการใช้งาน
        </Link>
      </Typography>
    </StyledBox>
  )
}

export default Consent
