import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { useDispatch } from 'react-redux'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { FILE_TYPE } from 'src/constants/eLearning'
import { setFileRef } from 'src/redux/slices/eLearning/learning'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { handleDownloadFile } from '../../../RightPanel/ModuleLabel/events'
import { MediaBoxStyled } from '../Styled'
import {
  StyledFileRenderWrapper,
  StyledWrapper,
  StyledZipDisplay,
  StyledZipDownloadBtn,
  imageStyle,
} from './Styled'
import { handleFinishFile } from './events'

const FileRender = ({ selectedLesson }) => {
  const fileList = _.get(selectedLesson, 'fileList', [])
  const selectedIndex = _.get(selectedLesson, 'selectedFile', 0)
  const selectedFile = _.get(fileList, selectedIndex, {})
  const isFinished = _.get(selectedFile, 'isFinished', false)
  const ref = useRef(null)
  const dispatch = useDispatch()
  const isLg = isMediaWidthMoreThan('lg')
  const isNotSm = isMediaWidthMoreThan('sm')

  useEffect(() => {
    if (ref) dispatch(setFileRef(ref))
  }, [ref])

  if (selectedFile.fileType === _.lowerCase(FILE_TYPE.ZIP)) {
    return (
      <MediaBoxStyled key={selectedFile}>
        <StyledZipDisplay src="/icon/e-learning/ic_zip.svg" />

        <StyledZipDownloadBtn
          startIcon={<img src="/icon/ic_download.svg" />}
          onClick={() => dispatch(handleDownloadFile(selectedFile))}
        >
          ดาวน์โหลด
        </StyledZipDownloadBtn>
      </MediaBoxStyled>
    )
  }

  return (
    <MediaBoxStyled>
      <StyledWrapper>
        {isLg ? (
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            wheel={{ wheelDisabled: true }}
            ref={ref}
          >
            {() => (
              <TransformComponent
                wrapperStyle={{ ...imageStyle, height: '100%' }}
                contentStyle={imageStyle}
              >
                <FileList selectedFile={selectedFile} />
              </TransformComponent>
            )}
          </TransformWrapper>
        ) : (
          <FileList selectedFile={selectedFile} />
        )}
        {isNotSm && !isFinished && (
          <Button
            size="l"
            variant="contained"
            startIcon={<CheckCircle />}
            onClick={handleFinishFile}
          >
            เรียนบทนี้จบแล้ว
          </Button>
        )}
      </StyledWrapper>
    </MediaBoxStyled>
  )
}

export default FileRender

export const FileList = ({ selectedFile }) => {
  const backendApiUrl = window.__env__.REACT_APP_SOCKET_URL
  return (
    <StyledFileRenderWrapper>
      {_.get(selectedFile, 'fileName', []).map((img, index) => (
        <img key={index} src={`${backendApiUrl}/file${img}`} />
      ))}
    </StyledFileRenderWrapper>
  )
}
