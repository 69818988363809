export const MODULE_TYPE = {
  E_TESTING: 'E_TESTING',
  E_EVALUATION: 'E_EVALUATION',
  EMBED_CONTENT: 'EMBED_CONTENT',
  LINK: 'LINK',
  HTML: 'HTML',
  LINK_TO: 'LINK_TO_ANOTHER_WEBSITE',
  UPLOAD_FILE: 'UPLOAD_FILE',
  SCORM: 'SCORM',
}

export const MODULE_TYPE_TH = {
  [MODULE_TYPE.E_TESTING]: 'แบบทดสอบ',
  [MODULE_TYPE.E_EVALUATION]: 'แบบประเมิน',
  [MODULE_TYPE.EMBED_CONTENT]: 'ฝังเนื้อหา',
  [MODULE_TYPE.LINK_TO]: 'ลิงก์เว็บไซต์ภายนอก',
  [MODULE_TYPE.UPLOAD_FILE]: 'อัปโหลดไฟล์',
  [MODULE_TYPE.SCORM]: 'Scorm',
}

export const COURSE_TYPE = {
  GENERAL: 'GENERAL',
  OIC: 'OIC',
}

export const COURSE_TYPE_TH = {
  GENERAL: 'หลักสูตรทั่วไป',
  OIC: 'หลักสูตรระดับสูง',
}

export const DUE_DATE_TYPE = {
  SPECIFIC_DATE: 'SPECIFIC_DATE',
  NONE: 'NONE',
}

export const EXPIRATION_TYPE = {
  TIME_SPAN: 'TIME_SPAN',
  NONE: 'NONE',
}

export const TIME_SPAN_TYPE = {
  DAY: 'วัน',
  MONTH: 'เดือน',
  YEAR: 'ปี',
}

export const COURSE_ORDER = {
  DESC: 'DESC',
  ASC: 'ASC',
}

export const COURSE_ORDER_TH = {
  DESC: 'เรียงจากใหม่ไปเก่า',
  ASC: 'เรียงจากเก่าไปใหม่',
}

export const ENROLL_INPUT_TYPE = {
  UPLOAD: 'UPLOAD',
  CAPTURE: 'CAPTURE',
}

export const ENROLL_REKOGNITION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
}

export const REKOGNITION_EVENT = {
  ENROLL: 'ENROLL',
  ATTEND: 'ATTEND',
  LEARN: 'LEARN',
}

export const E_LEARNING_LEARNER_STATUS = {
  INELIGIBLE: 'INELIGIBLE',
  ELIGIBLE_ANNOUNCE: 'ELIGIBLE_ANNOUNCE',
  WAITING_ANNOUNCE: 'WAITING_ANNOUNCE',
  WAITING_CHECK: 'WAITING_CHECK',
  REJECTED_DOCUMENT: 'REJECTED_DOCUMENT',
  CANCEL: 'CANCEL',
  CANCELED: 'CANCELED',
  CANCEL_CLASS: 'CANCEL_CLASS',
  PASS: 'PASS',
  CANCELED_MA: 'CANCELED_MA',
}

export const E_LEARNING_LEARNER_RESULT = {
  PASS: 'PASS',
  FAIL: 'FAIL',
  COMPLETE: 'COMPLETE',
}

export const E_LEARNING_LEARNER_RESULT_TH = {
  [E_LEARNING_LEARNER_RESULT.PASS]: 'จบการอบรม (ผ่าน)',
  [E_LEARNING_LEARNER_RESULT.FAIL]: 'จบการอบรม (ไม่ผ่าน)',
  [E_LEARNING_LEARNER_RESULT.COMPLETE]: 'จบการอบรม (ผ่าน)',
}

export const E_LEARNING_LEARNER_STATUS_TH = {
  [E_LEARNING_LEARNER_STATUS.INELIGIBLE]: 'ไม่มีสิทธิ์เข้าเรียน',
  [E_LEARNING_LEARNER_STATUS.ELIGIBLE_ANNOUNCE]: 'ประกาศสิทธิ์แล้ว',
  [E_LEARNING_LEARNER_STATUS.WAITING_ANNOUNCE]: 'รอประกาศสิทธิ์',
  [E_LEARNING_LEARNER_STATUS.WAITING_CHECK]: 'รอตรวจสอบเอกสาร',
  [E_LEARNING_LEARNER_STATUS.REJECTED_DOCUMENT]: 'เอกสารไม่ผ่าน',
  [E_LEARNING_LEARNER_STATUS.CANCEL]: 'ยกเลิกการสมัคร',
  [E_LEARNING_LEARNER_STATUS.CANCELED]: 'ยกเลิกโดยแอดมิน',
  [E_LEARNING_LEARNER_STATUS.CANCELED_MA]: 'ยกเลิกโดยผู้จัดการ',
  [E_LEARNING_LEARNER_STATUS.CANCEL_CLASS]: 'ยกเลิกคลาส',
}

export const FILE_TYPE = {
  DOC: 'DOC',
  DOCX: 'DOCX',
  PPT: 'PPT',
  PPTX: 'PPTX',
  PDF: 'PDF',
  ZIP: 'ZIP',
}

export const E_LEARNING_LEARNER_LOG_RESULT = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  INPROGRESS: 'INPROGRESS',
  PASS: 'PASS',
  FAIL: 'FAIL',
}

export const LEARNING_STATUS = {
  PENDING: 'PENDING',
  INPROGRESS: 'INPROGRESS',
  COMPLETE: 'COMPLETE',
}

export const BATCH_QUESTION_STATUS = {
  SUCCESS: 'SUCCESS',
  WAITING: 'WAITING',
}

export const E_LEARNING_CLICK_LOG_RESULT = {
  PASS: 'PASS',
  NOT_PASS: 'NOT_PASS',
}

export const E_LEARNING_STATUS = {
  WAITING: 'WAITING',
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  COMPLETED: 'COMPLETED',
}

export const headCells = [
  {
    id: 'date',
    disablePadding: false,
  },
  {
    id: 'duration',
    disablePadding: false,
  },
]

export const COURSE_SORT = {
  POPULAR: 'POPULAR',
  NEWEST: 'NEWEST',
}
